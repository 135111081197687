import { FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { purple_color } from "../../../../../../app/styles/variables";
import { IChannelAudienceRadio } from "./IChannelAudienceRadio";

export const ChannelAudienceRadio: FC<IChannelAudienceRadio> = (props) => {
  const { value = false, changeField, disabled, code } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === "true";

    changeField(code, value);
  };

  return (
    <FormGroup>
      <RadioGroup defaultValue={value} row value={value} onChange={handleChange}>
        <FormControlLabel
          value={true}
          control={<Radio />}
          label={
            <Typography sx={{ ...(!disabled && { "&:hover": { color: purple_color } }) }}>
              Да
            </Typography>
          }
          disabled={disabled}
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={
            <Typography sx={{ ...(!disabled && { "&:hover": { color: purple_color } }) }}>
              Нет
            </Typography>
          }
          disabled={disabled}
        />
      </RadioGroup>
    </FormGroup>
  );
};
