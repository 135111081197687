import { Avatar, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { IUserUpdateAvatarModal } from "./IUserUpdateAvatarModal";
import { CustomButton } from "../../../../../UI/Buttons/CustomButton/CustomButton";
import { AlertModal } from "../../../../../AlertModal/AlertModal";
import { observer } from "mobx-react-lite";
import updateUserStore from "../../../../../../store/updateUser.store";
import { CustomIconButton } from "../../../../../UI/Buttons/CustomIconButton/CustomIconButton";
import { Cancel } from "@mui/icons-material";

export const UserUpdateAvatarModal: FC<IUserUpdateAvatarModal> = observer((props) => {
  const { open, onClose, imageUrl, firstName, lastName } = props;

  const [isShowDeleteAlert, setIsShowDeleteAlert] = useState(false);
  const [isShowUpdateAlert, setIsShowUpdateAlert] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleShowDeleteAlert = () => {
    setIsShowDeleteAlert(true);
  };

  const handleCloseDeleteAlert = () => {
    setIsShowDeleteAlert(false);
  };

  const handleShowUpdateAlert = () => {
    setIsShowUpdateAlert(true);
  };

  const handleCloseUpdateAlert = () => {
    setIsShowUpdateAlert(false);
  };

  const confirmDelete = () => {
    updateUserStore.setImageUrl(null);
    updateUserStore
      .updateUserAvatar()
      .then(() => handleCloseDeleteAlert())
      .catch((err) => setError(err));
  };

  const confirmSubmit = (value: string) => {
    updateUserStore.setImageUrl(value);
    updateUserStore
      .updateUserAvatar()
      .then(() => handleCloseUpdateAlert())
      .catch((err) => setError(err));
  };

  const errorText = <Typography color={"error"}>{error}</Typography>;

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: 3 } }}>
      <DialogContent sx={{ position: "relative" }}>
        <CustomIconButton
          size="small"
          onClick={onClose}
          sx={{ position: "absolute", top: 0, right: 0 }}>
          <Cancel />
        </CustomIconButton>
        <Stack width={"250px"} rowGap={4} alignItems={"center"} position={"relative"}>
          <Stack spacing={"12px"}>
            <Typography component={"h2"} fontSize={"22px"}>
              Фото профиля
            </Typography>
            <Typography fontSize={"14px"}>
              По фото профиля другие люди смогут вас узнавать, а вам будет проще определять, в какой
              аккаунт вы вошли.
            </Typography>
          </Stack>
          <Avatar
            src={imageUrl || ""}
            alt={`${firstName} ${lastName}`}
            sx={{ height: 200, width: 200 }}
          />
          {imageUrl ? (
            <Stack direction={"row"} justifyContent={"space-around"} width={"100%"}>
              <CustomButton onClick={handleShowUpdateAlert}>Сменить</CustomButton>
              <CustomButton onClick={handleShowDeleteAlert}>Удалить</CustomButton>
            </Stack>
          ) : (
            <CustomButton onClick={handleShowUpdateAlert}>Добавить фото профиля</CustomButton>
          )}
        </Stack>
      </DialogContent>
      <AlertModal
        open={isShowDeleteAlert}
        title={"Внимание!"}
        subtext={"Действительно удалить?"}
        onSubmit={confirmDelete}
        onClose={handleCloseDeleteAlert}
        colorSubmit="error"
        buttonCancelName="Отмена"
        buttonSubmitName="Удалить"
        subContent={errorText}
      />
      <AlertModal
        open={isShowUpdateAlert}
        title={"Внимание!"}
        subtext={"Вставьте URL (ссылку) на фото"}
        onSubmit={confirmSubmit}
        onClose={handleCloseUpdateAlert}
        colorSubmit="error"
        buttonCancelName="Отмена"
        buttonSubmitName="Сохранить"
        showTextField
        subContent={errorText}
      />
    </Dialog>
  );
});
