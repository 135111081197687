import { FC, useState } from "react";
import { IContragentIntegration } from "./IContragentIntegration";
import { FinancesContrAgent } from "../../../../../../../FinancesCreatePage/ui/FinancesContrAgent/FinancesContrAgent";
import { observer } from "mobx-react-lite";
import { IFinancePaymentEditRequest } from "../../../../../../../../services/payments/IPaymentsRequest";
import PaymentsService from "../../../../../../../../services/payments/payments.service";
import { AxiosError } from "axios";
import integrationPageStore from "../../../../../../../../store/integrationPage.store";
import { checkNumberBoolean } from "../../../../../../../../shared/functions/functions";
import { NotificationAlerts } from "../../../../../../../../components/NotificationAlerts/NotificationAlerts";

export const ContragentIntegration: FC<IContragentIntegration> = observer((props) => {
  const { readOnly } = props;

  const [isEdit, setEdit] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState<null | string>(null);

  const [documentContact, setDocumentContact] = useState(
    (isEdit
      ? integrationPageStore.editCounteragent?.contact?.documentContact
      : integrationPageStore.counteragent?.contact?.documentContact) || ""
  );
  const [contractLink, setContractLink] = useState(
    (isEdit
      ? integrationPageStore.editCounteragent?.contractLink
      : integrationPageStore.counteragent?.contractLink) || ""
  );

  const startEdit = () => {
    integrationPageStore.startEditCounterAgent();
    setEdit(true);
  };

  const closeEdit = () => {
    integrationPageStore.closeEditCounterAgent();
    setDocumentContact(integrationPageStore.counteragent?.contact?.documentContact || "");
    setContractLink(integrationPageStore.counteragent?.contractLink || "");
    setEdit(false);
  };

  const submit = () => {
    if (
      integrationPageStore.oplataId &&
      integrationPageStore?.editCounteragent?.id &&
      checkNumberBoolean(integrationPageStore.paymentUpdateId)
    ) {
      setLoading(true);
      setError(null);

      const option: IFinancePaymentEditRequest = {
        update_id: integrationPageStore.paymentUpdateId!,
        id: integrationPageStore.oplataId,
        counteragent: {
          id: integrationPageStore?.editCounteragent?.id,
          contact: { documentContact, id: integrationPageStore?.editCounteragent?.contact?.id },
          contractLink: contractLink,
        },
      };
      PaymentsService.paymentEditCounterAgent(option)
        .then((res) => {
          integrationPageStore.setCounteragent(res.data?.counteragent);
          integrationPageStore.setPaymentUpdateId(res.data?.update_id || null);
          integrationPageStore.setContract(res.data.contract);
          integrationPageStore.setAttachment(res.data.attachment);
          integrationPageStore.setSupplyContract(res.data.supplyContract);
          integrationPageStore.setTrackNumber(res.data.trackNumber);
          integrationPageStore.setCounteragentTrackNumber(res.data.counteragentTrackNumber);
          integrationPageStore.setAdditionalAgreement(res.data.additionalAgreement);
          integrationPageStore.setIsOriginalsReceived(res.data.isOriginalsReceived);

          closeEdit();
          setLoading(false);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            setLoading(false);
            setError(err?.response?.data?.message);
          }
        });
    }
  };

  return (
    <>
      <FinancesContrAgent
        isViewEditModeFields
        documentContact={documentContact}
        setDocumentContact={setDocumentContact}
        setContractLink={setContractLink}
        isEditMode={isEdit}
        isShowEditIcon
        counteragent={
          isEdit ? integrationPageStore.editCounteragent : integrationPageStore.counteragent
        }
        integrationContacts={integrationPageStore.integrationContacts || []}
        setCounteragent={integrationPageStore.setEditCounteragent}
        disabled={isLoading}
        onCloseEditButton={closeEdit}
        onOpenEditButton={startEdit}
        onSubmitEditButton={submit}
        loadingEditButton={false}
        sxContAgentWidth={{ width: { xs: "100%", lg: "80%", xl: "350px" } }}
        readOnly={readOnly}
      />
      <NotificationAlerts
        error={{
          text: isError ? isError : "",
          onClose: () => setError(null),
          open: Boolean(isError),
          autoHideDuration: 5000,
        }}
      />
    </>
  );
});
