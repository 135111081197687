export enum ALL_ROLES {
  HEAD = "head",
  ADMIN = "admin",
  PRE_HEAD_MANAGER = "pre-head/manager",
  SEARCH_HEAD_MANAGER = "search-head/manager",
  FINANCE_MANAGER = "finance-manager",
  RELEASE_MANAGER = "release_manager",
  SEARCH_MANAGER = "search_manager",
  trainee = "trainee",
  TRAINEE_SEARCH = "trainee-search",
  TRAINEE_RELEASE = "trainee-release",
  CONTENT_MANAGER = "content-manager",
  EMPTY = "",
}

export enum ALL_RULES {
  ADMIN = "admin",

  ADD_USER = "add_user",
  EDIT_USER = "edit_user",
  one_percent_for_old_channels = "1_percent_for_old_channels",
  get_integrations = "get_integrations",
  edit_self_integrations = "edit_self_integrations",
  edit_other_integrations = "edit_other_integrations",
  create_integration = "create_integration",

  get_channels = "get_channels",
  edit_channels = "edit_channels",
  create_channel = "create_channel",
  create_tz = "create_tz",
  edit_foundById = "edit_foundById",
  edit_releaseById = "edit_releaseById",

  edit_payment = "edit_payment",

  read_coefs = "read_coefs",
  edit_plan = "edit_plan",
  concord = "concord",

  concord_ = "concord_",
  concord_1 = "concord_1",
  concord_2 = "concord_2",
  concord_3 = "concord_3",

  release = "release",
  search = "search",

  search_head = "search-head",
  release_head = "release-head",

  lk_my_motivation = "lk_my_motivation",
  lk_finances = "lk_finances",
  lk_my_integrations = "lk_my_integrations",

  block_channel = "block_channel",

  head = "head",

  delete_integrations = "delete_integrations",
  DELETE_INTEGRATION = "delete_integration",
  edit_date_given = "edit_date_given",
  DELETE_PAYMENT = "delete_payment",

  VIEW_TZ_PAGE = "view_tz_page",
  VIEW_ANALYTICS_PAGE = "view_analytics_page",
  VIEW_COUNTERAGENT_PAGE = "view_counteragent_page",
  VIEW_MOTIVATION_PAGE = "view_motivation_page",
  VIEW_CHANNELS_PAGE = "view_channels_page",

  view_payments_stats = "view_payments_stats",
  edit_allowUsage_tz = "edit_allowUsage_tz",

  view_channel_transfer_page = "view_channel_transfer_page",
  edit_channel_transfer = "edit_channel_transfer",
  EDIT_CANCEL_PAYMENT_STATUS = "edit_cancel_payment_status",
  EDIT_MOTIVATION_FOR_MANAGER = "edit_motivation_for_manager",
  EDIT_NO_RELEASE_PAYMENT_STATUS = "edit_no_release_payment_status",
  EDIT_REFUND_PAYMENT_STATUS = "edit_refund_payment_status",
  EDIT_TZ = "edit_tz",

  delete_channel = "delete_channel",

  SEARCH_MOTIVATIONS = "search-motivation",
  RELEASE_MOTIVATIONS = "release-motivation",
  MOTIVATIONS_FOR_SEARCH_MANAGERS = "motivation-for-search-managers",
  MOTIVATIONS_FOR_RELEASE_MANAGERS = "motivation-for-release-managers",
}
