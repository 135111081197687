import { ArrowForward, OpenInNew } from "@mui/icons-material";
import { Avatar, Box, Checkbox, Link, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { EmptyText } from "../../../../components/EmptyText/EmptyText";
import { PromokodAdd } from "../../../../components/Promokod/PromokodAdd";
import { GoodCategory } from "../../../../components/Table/ui/Fields/GoodCategory/GoodCategory";
import { PaymentStatus } from "../../../../components/Table/ui/Fields/PaymentStatus/PaymentStatus";
import { PriceEdit } from "../../../../components/Table/ui/Fields/PriceEdit/PriceEdit";
import { CustomIconButton } from "../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { IIntegrationsFullResponse } from "../../../../services/integration/IIntegrationsResponse";
import IntegrationsService from "../../../../services/integration/integrations.service";
import { IUpdateStatusesRequest } from "../../../../services/integration/IntegrationsRequest";
import { INTEGRATION_CARD_OPEN } from "../../../../shared/constants/searchParams";
import {
  getDatePlusCustomTime,
  priceTransormFormat,
  sendDatePlusCustomTime,
} from "../../../../shared/functions/functions";
import { ALL_RULES } from "../../../../shared/rules/interface/EnumRights";
import categoriesGoodsStore from "../../../../store/categoriesGoods.store";
import channelStore from "../../../../store/channel.store";
import dashboardStore from "../../../../store/dashboard.store";
import integrationPageStore from "../../../../store/integrationPage.store";
import integrationTableStore from "../../../../store/integrationTable.store";
import paymentsTableStore from "../../../../store/paymentsTable.store";
import prePaymentStore from "../../../../store/prePayment.store";
import repeatIntegrationStore from "../../../../store/repeatIntegration.store";
import userStore from "../../../../store/user.store";
import { PaymentOnCard } from "../../../ChannelCardPage/ui/ChannelAsideMain/ui/PaymentOnCard/PaymentOnCard";
import { VerifiedIcon } from "../../../ChannelCardPage/ui/ChannelAsideMain/ui/VerifiedByGov/VerifiedIcon/VerifiedIcon";
import { AsideCardDate } from "../../ui/AsideCardDate/AsideCardDate";
import { AsideCardGoods } from "../../ui/AsideCardGoods/AsideCardGoods";
import { AsideCardPrediction } from "../../ui/AsideCardPrediction/AsideCardPrediction";
import { AsideCardPromokod } from "../../ui/AsideCardPromokod/AsideCardPromokod";
import { AsideCardSentSamples } from "../../ui/AsideCardSentSamples/AsideCardSentSamples";
import { AsideReleaseManager } from "../../ui/AsideReleaseManager/AsideReleaseManager";
import { AsideTableStatistic } from "../../ui/AsideTableStatistic/AsideTableStatistic";
import { AsideTableTraficStatistic } from "../../ui/AsideTableTraficStatistic/AsideTableTraficStatistic";
import { TableAsideRelease } from "../../ui/TableAsideRelease/TableAsideRelease";
import { IIntegrationMain } from "./IIntegrationMain";
import {
  getFieldStatisticsCategories,
  getFieldStatisticsMain,
  getStatisticsCategories,
  getStatisticsMain,
} from "./shared/dataList";
import { AddIntegrationDate } from "./ui/AddIntegrationDate/AddIntegrationDate";
import { AddShortLink } from "./ui/AddShortLink/AddShortLink";
import { CheckListCard } from "./ui/CheckListCard/CheckListCard";
import { GuaranteeReissueControl } from "./ui/GuaranteeReissueControl/GuaranteeReissueControl";
import { GuranteePrice } from "./ui/GuranteePrice/GuranteePrice";
import { IntegrationComments } from "./ui/IntegrationComments/IntegrationComments";
import { IntegrationDeleteBtn } from "./ui/IntegrationDeleteBtn/IntegrationDeleteBtn";
import { IntegrationLink } from "./ui/IntegrationLink/IntegrationLink";
import { IntegrationStatus } from "./ui/IntegrationStatus/IntegrationStatus";
import { IntegrationTargetAudience } from "./ui/IntegrationTargetAudience/IntegrationTargetAudience";
import { StatusAddEmpty } from "./ui/StatusAddEmpty/StatusAddEmpty";
import { TechnicalTask } from "./ui/TechnicalTask/TechnicalTask";
import { VideoReview } from "./ui/VideoReview/VideoReview";

export const IntegrationMain: FC<IIntegrationMain> = observer((props) => {
  const {
    id,
    closeModal,
    data,
    dataOrder,
    isOrdersLoading,
    cpv,
    price,
    setCpvValue,
    setPriceValue,
    promokodList,
    setPromokodList,
    dataOnSource,
    isMetricError,
    isMetricLoading,
    date,
    setDate,
    setTzValue,
    tzValue,
    update_id,
    setUpdateId,
    link,
    setLink,
    setStatusValue,
    statusValue,
    goods,
    setGoods,
    goodsCategoryCode,
    setGoodsCategoryCode,
    readOnly,
    channelSearchParamsKey,
    changePage,
    setQrCodeLink,
    qrCodeLink,
    initialPrice,
    setInitialPrice,
    checkUserEditPayment,
    isClone,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [sourceValue, setSourceValue] = useState<string>(data?.source);
  const [shortLink, setShortLink] = useState<string | null>(
    data?.shortLink === "" ? null : data?.shortLink
  );

  const channelCardOpen = () => {
    searchParams.set(channelSearchParamsKey, `${data.channel.id}`);
    setSearchParams(searchParams);
  };

  const checkUserEdit = (): boolean => {
    if (userStore?.user?.rights?.find((x) => x.code === ALL_RULES.ADMIN)) {
      return true;
    } else if (userStore.user?.rights.find((x) => x.code === ALL_RULES.block_channel)) {
      return true;
    }
    if (userStore.user?.id === data?.foundBy?.id) {
      return true;
    } else return false;
  };

  const checkUserRoleDeleted = useCallback((): boolean => {
    return !!userStore.user?.rights.find(
      (x) =>
        x?.code === ALL_RULES.ADMIN ||
        x?.code === ALL_RULES.delete_integrations ||
        x?.code?.includes(ALL_RULES.release)
    );
  }, []);

  const checkUserDeleted = useCallback((userId: number | null): boolean => {
    return !!(
      userStore?.user?.id === userId ||
      userStore?.user?.rights?.some((x) => x.code === ALL_RULES.ADMIN)
    );
  }, []);

  const editGoodsPredictions = (data: IIntegrationsFullResponse) => {
    integrationPageStore.setAverageOrderSumm(data.averageCheck);
    integrationPageStore.setPredictionViews(data.prediction_views);
    integrationPageStore.setPredictionTraffic(data.prediction_traffic);
    integrationPageStore.setPredictionCpv(data.prediction_cpv);
    integrationPageStore.setPredictionCpvNew(data.prediction_cpv_new);
    integrationPageStore.setPredictionCtr(data.prediction_ctr);
    integrationPageStore.setPredictionCr(data.prediction_cr);
    integrationPageStore.setPredictionOrders(data.prediction_orders);
    integrationPageStore.setPredictionRoi(data.prediction_roi);
    integrationPageStore.setPredictionRoiNew(data.prediction_roi_new);
  };

  const changePrice = (value: string) => {
    setPriceValue(value);
    integrationPageStore.setTotalSumm(+value);
  };

  const changeInitialPrice = (value: string) => {
    setInitialPrice(+value);
  };

  const onChangePriceAndStatus = (
    id: number,
    status: IIntegrationsFullResponse["status"],
    update_id: number
  ) => {
    setStatusValue(status?.code);
    integrationTableStore.changeUpdateIdItem(id, update_id, status?.code);
  };

  const changeFieldHandler = (option: IUpdateStatusesRequest) => {
    integrationTableStore.changeField(option);
    dashboardStore.changeField(option);
    paymentsTableStore.changeField(option);
    prePaymentStore.changeField(option);
    repeatIntegrationStore.changeField(option);
  };

  const handleOpenIntegration = () => {
    searchParams.delete(INTEGRATION_CARD_OPEN);
    if (integrationPageStore.repeatIntegrationId !== null) {
      const id = integrationPageStore.repeatIntegrationId;
      setTimeout(() => {
        searchParams.set(INTEGRATION_CARD_OPEN, id.toString());
        setSearchParams(searchParams);
      }, 200);
    }
  };

  const handleUpdateGuaranteeTakeLimit = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const id = data.guarantee?.id;

    if (id) {
      integrationPageStore.updateGuaranteeTakeLimit(id, checked);
    }
  };

  const readOnlyTakeLimitByViews =
    statusValue === "reissue_completed" || statusValue === "reissue_views";

  const readOnlyStatusReissue = statusValue === "reissue";

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            md: "1fr 1fr",
            lg: `1fr minmax(32%, 1fr) ${
              integrationPageStore.chatOpen === false ? "0.08fr" : "1fr"
            }`,
          },
          columnGap: 2,
          rowGap: 2,
          transition: "all ease .3s",
        }}>
        <AsideCardLayout
          headerTitle="Основное"
          sx={{ height: "fit-content" }}
          rightContent={
            <Stack direction={"row"}>
              {integrationPageStore.isVerifiedByGov !== null && (
                <VerifiedIcon
                  isVerified={integrationPageStore.isVerifiedByGov}
                  audience={integrationPageStore.audience || 0}
                />
              )}
              {integrationPageStore.isPaymentOnCard !== null && (
                <PaymentOnCard
                  service={IntegrationsService}
                  setPaymentOnCard={integrationPageStore.setPaymentOnCard}
                  changeField={changeFieldHandler}
                  onChangeUpdateId={setUpdateId}
                  id={id}
                  update_id={update_id}
                  isPaymentOnCard={integrationPageStore.isPaymentOnCard}
                  readOnly={!checkUserEdit()}
                />
              )}
              <VideoReview
                checked={integrationPageStore.isVideoReview || false}
                id={data.id}
                readOnly={readOnly}
                setUpdateId={setUpdateId}
                update_id={update_id}
              />
            </Stack>
          }>
          <Stack direction={"column"} spacing={"6px"}>
            <Box
              sx={{
                mt: "6px",
                display: "grid",
                gridTemplateColumns: "min-content 1fr",
                columnGap: "4px",
                alignItems: "center",
              }}>
              <Typography noWrap>Имя канала:</Typography>
              <Box sx={{ display: "flex", overflow: "hidden", alignItems: "center" }}>
                {data.channel?.name ? (
                  <Avatar
                    src={channelStore?.channels?.[data?.channel?.type?.code]?.icon}
                    alt={data?.channel?.type?.code}
                    sx={{ height: 20, width: 20, mr: 0.5 }}
                  />
                ) : null}
                {data.channel?.name ? (
                  <Link
                    onClick={channelCardOpen}
                    color={"secondary"}
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}>
                    {data.channel.name}
                  </Link>
                ) : (
                  <EmptyText>Канал отсутствует</EmptyText>
                )}
                {data.channel?.link ? (
                  <Tooltip title={<Typography>Ссылка на канал</Typography>}>
                    <Box>
                      <CustomIconButton
                        size="small"
                        onClick={() => window.open(data?.channel?.link, "_blank")}>
                        <ArrowForward fontSize="small" />
                      </CustomIconButton>
                    </Box>
                  </Tooltip>
                ) : null}
              </Box>
            </Box>
            <Typography>
              Новизна канала: {data?.firstIntegrationWithChannel ? "Новый" : "Старый"}
            </Typography>
            <Typography>Тематика канала: {data?.channel?.theme?.name || "Отсутствует"}</Typography>
            <PriceEdit
              id={+id}
              price={initialPrice !== null ? initialPrice : 0}
              onChangeCpv={setCpvValue}
              isEditMode={!readOnly}
              title="Прайс"
              onChangeInitialPrice={changeInitialPrice}
              fullWidth
              field="initialPrice"
              update_id={update_id}
              onChangeUpdateId={setUpdateId}
              isIntegrationClone={integrationPageStore.isClone}
            />
            <PriceEdit
              id={+id}
              price={price !== null ? price : 0}
              onChangeCpv={setCpvValue}
              isEditMode={!readOnly}
              title="Стоимость"
              onChangePrice={changePrice}
              fullWidth
              field="price"
              update_id={update_id}
              onChangeUpdateId={setUpdateId}
              onChangeIntegrationsForPayment={integrationPageStore.onChangeIntegrationsForPayment}
              onChangePriceAndStatus={onChangePriceAndStatus}
              isIntegrationClone={integrationPageStore.isClone}
            />
            {readOnlyStatusReissue && (
              <Typography color={"#f44336"}>
                Измените статус, чтобы начать редактировать интеграцию
              </Typography>
            )}
            <Stack direction={"row"} alignItems={"center"} spacing={statusValue ? 1 : 0}>
              <Typography>Статус:</Typography>
              {statusValue ? (
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <IntegrationStatus
                    id={data.id}
                    update_id={update_id}
                    type={statusValue}
                    readOnly={readOnly}
                    onChangeUpdateId={setUpdateId}
                    integrationId={data.id}
                    setStatusValue={setStatusValue}
                    isVerifiedByGov={integrationPageStore.isVerifiedByGov}
                  />
                  {integrationPageStore.repeatIntegrationId && (
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <Typography>
                        по интеграции {integrationPageStore.repeatIntegrationId}
                      </Typography>
                      {!isClone && integrationPageStore.repeatIntegrationStatus && (
                        <IntegrationStatus
                          id={data.id}
                          update_id={update_id}
                          type={integrationPageStore.repeatIntegrationStatus.code}
                          readOnly={true}
                          repeatIntegrationStatus
                          onChangeUpdateId={setUpdateId}
                          integrationId={data.id}
                          setStatusValue={setStatusValue}
                          isVerifiedByGov={integrationPageStore.isVerifiedByGov}
                        />
                      )}
                      <CustomIconButton onClick={handleOpenIntegration}>
                        <OpenInNew />
                      </CustomIconButton>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <StatusAddEmpty
                  id={data.id}
                  update_id={update_id}
                  readOnly={readOnly}
                  onChangeUpdateId={setUpdateId}
                  setStatus={setStatusValue}
                />
              )}
              {checkUserRoleDeleted() &&
                checkUserDeleted(Number(integrationPageStore?.releaseUserId)) &&
                statusValue === "planned" && <IntegrationDeleteBtn id={id} update_id={update_id} />}
            </Stack>
            <Typography display={"flex"} gap={1}>
              Метка:{" "}
              {sourceValue ? sourceValue.toLowerCase() : <EmptyText>метка отсутствует</EmptyText>}
            </Typography>
            {promokodList && id ? (
              <AsideCardPromokod
                onChangeSourceValue={setSourceValue}
                readOnly={readOnly || readOnlyStatusReissue}
                onChangeValue={setPromokodList}
                isEditMode
                id={id}
                promokod={promokodList?.code}
                dummies={promokodList?.dummies}
                onChangeUpdateId={setUpdateId}
                isActivated={promokodList?.isActivated}
                integrationId={id}
                update_id={update_id}
                setLink={setLink}
                setShortLinkChange={setShortLink}
                disableActiveOnSiteBtn={goods?.length === 0 || goodsCategoryCode == null}
                goodsCategoryCode={goodsCategoryCode}
                integrationIsClone={integrationPageStore.isClone}
                repeatIntegrationId={integrationPageStore.repeatIntegrationId}
              />
            ) : (
              <PromokodAdd
                readOnly={readOnly}
                id={+id}
                title="Промокод:"
                subTitle="Добавить промокод"
                onChangeValue={setPromokodList}
                onChangeSource={setSourceValue}
                placeholder="Введите промокод"
                onChangeUpdateId={setUpdateId}
                setLink={setLink}
              />
            )}
            <IntegrationLink
              link={link}
              title="Ссылка"
              tooltip
              id={+id}
              field="link"
              update_id={update_id}
              setUpdateId={setUpdateId}
              setLink={setLink}
              deleteModalTitle={"Вы действительно хотите удалить основную ссылку?"}
              readOnly={readOnlyStatusReissue}
            />
            {shortLink !== null && shortLink?.trim() !== "" ? (
              <IntegrationLink
                link={shortLink}
                title="Сокращенная ссылка:"
                id={+id}
                field="shortLink"
                update_id={update_id}
                setUpdateId={setUpdateId}
                setLink={setShortLink}
                tooltip
                deleteModalTitle={"Вы действительно хотите удалить короткую ссылку?"}
                readOnly={readOnlyStatusReissue}
              />
            ) : (
              <AddShortLink
                id={+id}
                readOnly={readOnly || readOnlyStatusReissue}
                shortLink={shortLink}
                setShortLink={setShortLink}
                setUpdateId={setUpdateId}
                update_id={update_id}
              />
            )}
            <IntegrationLink
              link={qrCodeLink}
              title="Ссылка на QR-код"
              tooltip
              id={+id}
              isEditMode={!readOnlyStatusReissue}
              field="qrCodeLink"
              update_id={update_id}
              setUpdateId={setUpdateId}
              setLink={setQrCodeLink}
              deleteModalTitle={"Вы действительно хотите удалить ссылку на QR-код?"}
              readOnly={readOnlyStatusReissue}
            />
            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ flexWrap: "wrap" }}>
              <Typography display={"flex"} gap={1}>
                Товарная категория:{" "}
                {goodsCategoryCode ? <GoodCategory code={goodsCategoryCode} /> : <EmptyText />}
              </Typography>
              {goodsCategoryCode && integrationPageStore.integrationTargetAudience ? (
                <IntegrationTargetAudience
                  targetAudience={integrationPageStore.integrationTargetAudience}
                  audienceData={data?.channel}
                />
              ) : null}
            </Stack>
            <Stack spacing={0.5} direction={{ sm: "row" }} alignItems={{ sm: "center" }}>
              <Typography>Техническое задание:</Typography>
              <TechnicalTask
                goods={goods}
                width={"100%"}
                maxWidth={"350px"}
                goodsCategoryCode={goodsCategoryCode}
                integrationId={id}
                update_id={update_id}
                readOnly={readOnly || readOnlyStatusReissue}
                iniValue={tzValue}
                setStateValue={setTzValue}
                onChangeUpdateId={setUpdateId}
                channelType={data.channel?.type?.code}
                setLink={setLink}
                setGoods={setGoods}
                setGoodsCategoryCode={setGoodsCategoryCode}
              />
            </Stack>
            <Stack direction={"column"} spacing={"6px"}>
              {isClone ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}>
                    <Typography display={"flex"} gap={1}>
                      {`Не хватило просмотров: ${
                        integrationPageStore.guarantee?.viewsCount &&
                        integrationPageStore.guarantee.viewsCount
                      }`}
                    </Typography>
                  </Box>
                  <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                    <Checkbox
                      disabled={readOnlyTakeLimitByViews || readOnlyStatusReissue}
                      checked={integrationPageStore.guarantee?.takeLimitByViews}
                      onChange={handleUpdateGuaranteeTakeLimit}
                    />
                    <Typography>Набор до количества просмотров</Typography>
                  </Stack>
                  <Typography>
                    Перевыпуск по гаранту:{" "}
                    {integrationPageStore.guarantee?.repeatGuaranteeDate &&
                      getDatePlusCustomTime({
                        value: integrationPageStore.guarantee?.repeatGuaranteeDate.toString(),
                        format: "DD.MM.YYYY HH:mm",
                      })}
                  </Typography>
                </>
              ) : (
                <>
                  <GuranteePrice
                    readOnly={readOnly || !!integrationPageStore.repeatIntegrationId}
                    value={
                      integrationPageStore.guarantee?.viewsCount !== null &&
                      integrationPageStore.guarantee?.viewsCount !== undefined
                        ? integrationPageStore.guarantee?.viewsCount
                        : null
                    }
                    integrationId={data?.id}
                    id={integrationPageStore.guarantee?.id || null}
                    iconColor={integrationPageStore.guarantee?.status?.color || null}
                    setUpdateId={setUpdateId}
                    update_id={update_id}
                    isGuaranteed={Boolean(integrationPageStore.guarantee?.repeat)}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}>
                    <Typography display={"flex"}>
                      Дата окончания гаранта:{" "}
                      {integrationPageStore.guarantee?.lastDate ? (
                        getDatePlusCustomTime({
                          value: integrationPageStore.guarantee?.lastDate,
                          format: "DD.MM.YYYY HH:mm",
                        })
                      ) : (
                        <EmptyText ml={0.5} />
                      )}
                    </Typography>
                    {integrationPageStore.guarantee?.viewsInLastDay ? (
                      <Typography>
                        {priceTransormFormat(integrationPageStore.guarantee.viewsInLastDay, false)}
                      </Typography>
                    ) : null}
                  </Box>
                  {integrationPageStore.guarantee?.repeatGuaranteeDate && (
                    <Box>
                      <GuaranteeReissueControl
                        isGuaranteed={!!integrationPageStore.guarantee?.repeatGuaranteeDate}
                        readOnly={
                          readOnly ||
                          integrationPageStore?.guarantee?.viewsCount === null ||
                          integrationPageStore?.guarantee?.viewsCount === undefined
                        }
                        integrationId={data?.id}
                        id={integrationPageStore.guarantee?.id || null}
                        setUpdateId={setUpdateId}
                        update_id={update_id}
                        repeatGuaranteeDate={
                          integrationPageStore?.guarantee?.repeatGuaranteeDate &&
                          new Date(integrationPageStore?.guarantee?.repeatGuaranteeDate)
                        }
                      />
                    </Box>
                  )}
                </>
              )}
            </Stack>
            <Stack spacing={0.5} direction={{ sm: "row" }} alignItems={{ sm: "center" }}>
              <Typography mr={1}>Выпускающий менеджер:</Typography>
              <AsideReleaseManager
                id={id}
                initValue={integrationPageStore.releaseUserId}
                update_id={update_id}
                onChangeUpdateId={setUpdateId}
                field={"releaseUser"}
                fieldNotCode="releaseUserId"
                readOnly={readOnly || readOnlyStatusReissue}
              />
            </Stack>
            {date ? (
              <AsideCardDate
                title="Запланированная дата:"
                date={date}
                setValue={setDate}
                id={data.id}
                field="integration_date"
                update_id={update_id}
                readonly={readOnly || readOnlyStatusReissue}
                fullWidth
                onChangeUpdateId={setUpdateId}
              />
            ) : (
              <AddIntegrationDate
                id={data.id}
                update_id={update_id}
                onChangeUpdateId={setUpdateId}
                readOnly={readOnly || readOnlyStatusReissue}
              />
            )}
            <Stack spacing={1}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "start", sm: "center" }}
                flexWrap={"wrap"}>
                <Stack direction={"row"} alignItems={"center"} flexWrap={"wrap"} sx={{ mr: 1 }}>
                  <Typography sx={{ mr: 1, whiteSpace: "nowrap" }}>Статус оплаты:</Typography>
                  {integrationPageStore.status ? (
                    <PaymentStatus
                      id={id}
                      statusCode={integrationPageStore.status?.code}
                      type="payment"
                      isVerifiedByGov={integrationPageStore.isVerifiedByGov}
                      readOnly
                    />
                  ) : (
                    <EmptyText sx={{ whiteSpace: "nowrap" }}>Оплата не создана</EmptyText>
                  )}
                </Stack>
                {(integrationPageStore.isVerifiedByGov || checkUserEditPayment) && (
                  <>
                    {integrationPageStore.status && !readOnlyStatusReissue && (
                      <Box ml={{ sm: 2, md: 0 }}>
                        <Link
                          color={"secondary"}
                          onClick={() => changePage(3)}
                          sx={{ cursor: "pointer", whiteSpace: "nowrap" }}>
                          {integrationPageStore.status ? "Открыть оплату" : "Создать оплату"}
                        </Link>
                      </Box>
                    )}
                    {!integrationPageStore.status && !readOnlyStatusReissue && (
                      <Box ml={{ sm: 2, md: 0 }}>
                        <Link
                          color={"secondary"}
                          onClick={() => changePage(3)}
                          sx={{ cursor: "pointer", whiteSpace: "nowrap" }}>
                          {integrationPageStore.status ? "Открыть оплату" : "Создать оплату"}
                        </Link>
                      </Box>
                    )}
                  </>
                )}
              </Stack>
              {integrationPageStore.plannedPaidAt ? (
                <Typography>
                  Дата плановой оплаты:{" "}
                  {getDatePlusCustomTime({ value: integrationPageStore.plannedPaidAt })}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        </AsideCardLayout>
        <Stack gap={2}>
          <TableAsideRelease
            id={id.toString()}
            releases={data?.releases}
            readOnly={readOnly || readOnlyStatusReissue}
            onChangeUpdateId={setUpdateId}
            setUpdateId={setUpdateId}
            repeatIntegrationPromocode={integrationPageStore.isPromoForCloneChanged}
            repeatIntegrationId={integrationPageStore.repeatIntegrationId}
            handleOpenIntegration={handleOpenIntegration}
            isClone={integrationPageStore.isClone}
            setStatusValue={setStatusValue}
          />
          <AsideCardGoods
            id={+id}
            goods={goods}
            readOnly={readOnly || readOnlyStatusReissue}
            setLink={setLink}
            setGoods={setGoods}
            setUpdateId={setUpdateId}
            setGoodsCategoryCode={setGoodsCategoryCode}
            editGoodsPredictions={editGoodsPredictions}
            forFirstIntegrationWithChannel={Boolean(data.firstIntegrationWithChannel)}
          />
          <AsideCardSentSamples integration={data} readOnly={readOnlyStatusReissue} />
          <CheckListCard
            checkWidth={"50%"}
            data={data}
            id={id}
            setUpdateId={setUpdateId}
            update_id={update_id}
            readOnly={readOnly || readOnlyStatusReissue}
          />
        </Stack>
        <Box
          sx={{
            gridRowStart: { md: 4, lg: 1 },
            gridRowEnd: { xs: 7, md: 4, lg: 3 },
            gridColumn: { md: "1 / 3", lg: "3" },
          }}>
          <IntegrationComments data={data} id={id} readOnly={readOnly} />
        </Box>
        <Box
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: { xs: "auto", md: 3 },
            maxWidth: { xs: "calc(100vw - 32px)", sm: "calc(100vw - 70px - 32px)", md: "100%" },
          }}>
          <AsideCardLayout headerTitle="Прогнозы" showDivider={false}>
            <Box sx={{ mt: 1 }}>
              <AsideCardPrediction
                id={data.id}
                update_id={update_id}
                data={data}
                readOnly={readOnly}
                onChangeUpdateId={setUpdateId}
              />
            </Box>
          </AsideCardLayout>
          <AsideCardLayout sx={{ mt: 2 }} headerTitle="Фактические данные" showDivider={false}>
            <Box sx={{ mt: 1 }}>
              <AsideTableStatistic
                id={data.id}
                update_id={update_id}
                rows={getStatisticsMain(data)}
                onChangeUpdateId={setUpdateId}
                dataField={getFieldStatisticsMain(
                  data,
                  cpv !== null ? cpv?.toString() : "0",
                  `${integrationPageStore.views}`,
                  dataOrder,
                  data.cac
                )}
              />
            </Box>
          </AsideCardLayout>
          <AsideCardLayout
            sx={{ mt: 2 }}
            headerTitle="Категории товаров в заказе:"
            showDivider={false}>
            <Box sx={{ mt: 1 }}>
              {categoriesGoodsStore.categories && data.categories && (
                <AsideTableStatistic
                  rows={getStatisticsCategories(categoriesGoodsStore.categories)}
                  dataField={getFieldStatisticsCategories(data.categories)}
                />
              )}
              {data.categories === null && (
                <Typography
                  variant="h6"
                  color={"grey"}
                  sx={{ fontWeight: "normal", fontStyle: "italic", fontSize: "0.9rem" }}>
                  Товаров не найдено
                </Typography>
              )}
            </Box>
          </AsideCardLayout>
          <AsideCardLayout sx={{ mt: 2 }} headerTitle="Трафик" showDivider={false}>
            <Box sx={{ mt: 1 }}>
              {data && (
                <>
                  {!isMetricLoading && dataOnSource && (
                    <AsideTableTraficStatistic dataOnSource={dataOnSource} />
                  )}
                  {isMetricLoading && <Skeleton variant="rounded" height={48} width={"100%"} />}
                  {isMetricError && (
                    <Typography
                      variant="h6"
                      color={"error"}
                      sx={{ fontWeight: "normal", fontStyle: "italic", fontSize: "0.9rem" }}>
                      {isMetricError}
                    </Typography>
                  )}
                  {!isMetricLoading && dataOnSource === null && !isMetricError && (
                    <Typography
                      variant="h6"
                      color={"grey"}
                      sx={{ fontWeight: "normal", fontStyle: "italic", fontSize: "0.9rem" }}>
                      Динамики по трафику нет
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </AsideCardLayout>
        </Box>
      </Box>
    </Box>
  );
});
