import { makeAutoObservable } from "mobx";
import UsersService from "../services/users/users.service";
import { AxiosError } from "axios";
import { IUser } from "../entities/user/models/IUser";

class AllUsersStore {
  users: IUser[] | null = null;
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setItems(users: IUser[]) {
    this.users = users;
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  addUser = (user: IUser) => {
    this.users?.unshift(user);
  };

  updateUser = (user: IUser) => {
    if (!this.users) {
      return;
    }

    const userIndex = this.users?.findIndex((userItem) => userItem.id === user.id);

    if (userIndex !== -1) {
      this.users[userIndex] = user;
    }
  };
  getAllUsers() {
    this.setIsLoading(true);
    UsersService.getAllUsers()
      .then((res) => {
        // console.log(res, 'res all users')
        const sortedUsers = res.data.sort((a, b) => a.firstName.localeCompare(b.firstName));
        this.setItems(sortedUsers);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err all users')
        }
      })
      .finally(() => this.setIsLoading(false));
  }

  sortUsers = (type: keyof IUser, sortType: "asc" | "desc") => {
    if (!this.users) return;

    const sortedUsers = [...this.users].sort((a, b) => {
      let itemA = a[type] ?? null;
      let itemB = b[type] ?? null;

      // Если значения null, ставим их в конец списка
      if (itemA === null && itemB === null) return 0;
      if (itemA === null) return 1;
      if (itemB === null) return -1;

      if (typeof itemA === "string" && typeof itemB === "string") {
        return sortType === "asc"
          ? itemA.localeCompare(itemB, "ru", { sensitivity: "base" })
          : itemB.localeCompare(itemA, "ru", { sensitivity: "base" });
      }
      // Числовое сравнение
      if (typeof itemA === "number" && typeof itemB === "number") {
        return sortType === "asc" ? itemA - itemB : itemB - itemA;
      }

      // Стандартное сравнение строк (и fallback для остальных типов)
      return sortType === "asc" ? (itemA < itemB ? -1 : 1) : itemA > itemB ? -1 : 1;
    });

    this.users = sortedUsers;
  };
}

export default new AllUsersStore();
