import { AxiosResponse } from "axios";
import $users_api from "../../shared/api/usersAxios";
import { IUser, TCreateUser, TUpdateUser } from "../../entities/user/models/IUser";

export default class UsersService {
  static getAllUsers(): Promise<AxiosResponse<IUser[]>> {
    return $users_api.get("/api/users/all");
  }

  static createUser(newUser: TCreateUser): Promise<AxiosResponse<IUser>> {
    return $users_api.post("/auth/users/create", newUser);
  }

  static updateUser(updatedUser: TUpdateUser): Promise<AxiosResponse<IUser>> {
    return $users_api.post("/auth/users/update", updatedUser);
  }

  static updateAvatar(option: { imageUrl: string | null }): Promise<AxiosResponse<IUser>> {
    return $users_api.post("/api/users/updateImage", option);
  }
}
