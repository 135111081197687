import { IChannelFullResponse } from "../../../../../services/channel/ICnannelResponse";
import { getTableCellColor } from "../../../../../shared/functions/functions";
import { IChannelAudienceRequest } from "./dataList";

export const getAudiencePercentColor = (value: number): string => {
  const color = getTableCellColor({
    green: {
      from: 50,
      before: 999999,
      color: "#80dc9f",
    },
    orange: {
      from: 35,
      before: 49,
      color: "#ffdeab",
    },
    red: {
      from: 0,
      before: 34,
      color: "#ff5252",
    },
    value: value,
  });

  return color;
};

export const getAudienceDataOnChannelType = (
  data?: IChannelFullResponse | null,
  isNoData?: boolean,
  typeCode?: string
): IChannelAudienceRequest => {
  const type = data?.type?.code || typeCode;

  const allObj: IChannelAudienceRequest = {
    "13-17": isNoData ? null : data?.["13-17"]!,
    "18-24": isNoData ? null : data?.["18-24"]!,
    "25-34": isNoData ? null : data?.["25-34"]!,
    "35-44": isNoData ? null : data?.["35-44"]!,
    "45-54": isNoData ? null : data?.["45-54"]!,
    "55-64": isNoData ? null : data?.["55-64"]!,
    "65_plus": isNoData ? null : data?.["65_plus"]!,
    audience: isNoData ? null : data?.audience!,
    by: isNoData ? null : data?.by!,
    er: isNoData ? null : data?.er!,
    err: isNoData ? null : data?.err!,
    fromPC: isNoData ? null : data?.fromPC!,
    fromPhone: isNoData ? null : data?.fromPhone!,
    kz: isNoData ? null : data?.kz!,
    man: isNoData ? null : data?.man!,
    notSubs: isNoData ? null : data?.notSubs!,
    ru: isNoData ? null : data?.ru!,
    subs: isNoData ? null : data?.subs!,
    ua: isNoData ? null : data?.ua!,
    woman: isNoData ? null : data?.woman!,
    isBotAudience: isNoData ? null : data?.isBotAudience,
  };

  const telegramObj: IChannelAudienceRequest = {
    audience: allObj["audience"],
    er: allObj["er"],
    err: allObj["err"],
    isBotAudience: allObj?.isBotAudience,
  };

  const vkObj: IChannelAudienceRequest = {
    "13-17": allObj?.["13-17"]!,
    "18-24": allObj?.["18-24"]!,
    "25-34": allObj?.["25-34"]!,
    "35-44": allObj?.["35-44"]!,
    "45-54": allObj?.["45-54"]!,
    audience: allObj?.audience!,
    by: allObj?.by!,
    er: allObj?.er!,
    err: allObj?.err!,
    fromPC: allObj?.fromPC!,
    fromPhone: allObj?.fromPhone!,
    kz: allObj?.kz!,
    man: allObj?.man!,
    ru: allObj?.ru!,
    ua: allObj?.ua!,
    woman: allObj?.woman!,
    isBotAudience: allObj?.isBotAudience,
  };

  const instaAndYoutubeObj: IChannelAudienceRequest = {
    "13-17": allObj?.["13-17"]!,
    "18-24": allObj?.["18-24"]!,
    "25-34": allObj?.["25-34"]!,
    "35-44": allObj?.["35-44"]!,
    "45-54": allObj?.["45-54"]!,
    "55-64": allObj?.["55-64"]!,
    "65_plus": allObj?.["65_plus"]!,
    audience: allObj?.audience!,
    by: allObj?.by!,
    er: allObj?.er!,
    err: allObj?.err!,
    kz: allObj?.kz!,
    man: allObj?.man!,
    notSubs: allObj?.notSubs!,
    ru: allObj?.ru!,
    ua: allObj?.ua!,
    woman: allObj?.woman!,
    subs: allObj?.subs!,
    isBotAudience: allObj?.isBotAudience,
  };

  if (type === "telegram") {
    return telegramObj;
  } else if (type === "vk") {
    return vkObj;
  } else if (type === "instagram" || type === "youtube") {
    return instaAndYoutubeObj;
  } else return allObj;
};
