import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { TableDefault } from "../../../../components/Table/TableDefault";
import ChannelService from "../../../../services/channel/channel.service";
import channelProcessedStore from "../../../../store/channelProcessed.store";
import { getChannelsTableHeaderCells } from "../../shared/dataList";
import { CustomChannelFilter } from "../CustomChannelFilter/CustomChannelFilter";
import { processedChannelsFilters } from "../CustomChannelFilter/shared/dataList";
import { ProcessedChannelsList } from "./ui/ProcessedChannelsList";

export const ProcessedChannels = observer(() => {
  const [tableError, setTableError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSortType, setCurrentSortType] = useState("");

  const sortHandler = (type: string, sortType: "asc" | "desc") =>
    channelProcessedStore.sortTableItem(type, sortType);

  const getProcessedChannelData = async () => {
    setIsLoading(true);
    setTableError(null);
    try {
      const res = await ChannelService.getProcessedChannels();
      channelProcessedStore.setTableItems(res.data);
    } catch (err) {
      if (err instanceof AxiosError) {
        setTableError(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProcessedChannelData();
    return () => {
      channelProcessedStore.setTableItems([]);
      channelProcessedStore.resetFilters();
    };
  }, []);

  return (
    <>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
          Идёт загрузка таблицы... <CircularProgress sx={{ ml: 2 }} />
        </Box>
      )}
      {!isLoading && tableError && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
          <Typography variant="h5" color="error">
            Ошибка с таблицей! {tableError}
          </Typography>
        </Box>
      )}
      {!channelProcessedStore.tableItems.length && !isLoading && (
        <Stack alignItems={"center"} sx={{ mt: 2 }}>
          <Typography variant="h4">Каналы не найдены</Typography>
          <Typography variant="h5">Обновите страницу или зайдите позже</Typography>
        </Stack>
      )}
      {!isLoading && !tableError && channelProcessedStore.tableItems.length > 0 && (
        <Box display={"flex"} flexDirection={"column"} rowGap={2} overflow={"auto"}>
          <CustomChannelFilter
            setFilters={channelProcessedStore.setFilters}
            reset={channelProcessedStore.resetFilters}
            filters={processedChannelsFilters}
            title="Обработанные каналы"
          />
          {channelProcessedStore.filteredTableItems.length === 0 ? (
            <Typography>Каналы не найдены</Typography>
          ) : (
            <TableDefault
              onChangeCurrentSortType={setCurrentSortType}
              currentSortType={currentSortType}
              sortHandler={sortHandler}
              isShowSort
              maxHeight={"100%"}
              showDeleteIcon
              showBorderRadius={false}
              tableRow={getChannelsTableHeaderCells()}
              showBurgerCell>
              <ProcessedChannelsList cells={getChannelsTableHeaderCells()} />
            </TableDefault>
          )}
        </Box>
      )}
    </>
  );
});
