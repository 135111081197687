import { ISettingsSelected } from "../components/IntegrationTable/models/ISettingsSelected";
import {
  agrrementsAllCodesStrategy,
  arraysStrategy,
  botAudienceStrategy,
  categorySelect,
  changeNumFieldSelect,
  channelIdSelect,
  channelSelect,
  checkListStrategy,
  companyAccountSelect,
  contactsEmailSelect,
  contractorStrategy,
  ctrsSelect,
  datesSelect,
  defaultSelect,
  firstIntegrationWithChannelSelect,
  goodsCategoryCodeSelect,
  goodsSelect,
  govVerificationStrategy,
  guaranteeStrategy,
  integrationChannelSelect,
  integrationPaidSummSelect,
  integrationTablePaymentsStrategy,
  integrationThemeStrategy,
  lastCommentsSelect,
  linksSelect,
  moneySelect,
  paymentCheckboxStrategy,
  paymentPriceSelect,
  paymentsStrategy,
  predictionSelect,
  prePaymentStrategy,
  productSampleStrategy,
  promokodSelect,
  realeseSelect,
  releaseUsersSelect,
  simpleCheckboxStrategy,
  simpleInObjectCodeStrategy,
  sizeSelect,
  statusSelect,
  technicalTaskSelect,
  themeSelect,
  typesSelect,
  tzCheckboxStrategy,
  tzSelectCheckboxStrategy,
  tzSimpleStrategySelect,
  tzTextSelect,
} from "./strategyRealisation";

export type allAvailableField =
  | "id"
  | "roi"
  | "promokod"
  | "status"
  | "channel"
  | "dateFound"
  | "channel_id"
  | "releases"
  | "likes"
  | "views"
  | "comments"
  | "integration_date"
  | "source"
  | "prediction_views"
  | "prediction_orders"
  | "prediction_traffic"
  | "releaseUser"
  | "cr"
  | "cpv"
  | "ctr"
  | "price"
  | "initialPrice"
  | "channel_price"
  | "crThrough"
  | "channelType"
  | "channel_link"
  | "foundBy"
  | "dateGiven"
  | "channel_roi"
  | "channel_ctr"
  | "channel_cr"
  | "channel_cpv"
  | "channel_initialPrice"
  | "averageOrderSumm"
  | "lids"
  | "ordersSumm"
  | "channel_crThrough"
  | "canceledOrders"
  | "validOrders"
  | "cac"
  | "13-17"
  | "18-24"
  | "25-34"
  | "35-44"
  | "45-54"
  | "55-64"
  | "65_plus"
  | "woman"
  | "man"
  | "targetAudience"
  | "agency"
  | "contact"
  | "theme"
  | "channel_status"
  | "goods"
  | "category"
  | "channel_name"
  | "payment_id"
  | "amount"
  | "comment"
  | "commission"
  | "createdAt"
  | "hotSlot"
  | "paidAt"
  | "paidSumm"
  | "totalSumm"
  | "payment_channel"
  | "payment_status"
  | "channel_traffic"
  | "channel_canceledOrders"
  | "traffic"
  | "payments"
  | "crSite"
  | "minRequests"
  | "minOrders"
  | "releaseBy"
  | "ru"
  | "by"
  | "kz"
  | "ua"
  | "tz"
  | "subs"
  | "notSubs"
  | "fromPC"
  | "fromPhone"
  | "companyAccount"
  | "integration_paidSumm"
  | "audience"
  | "channel_ctrPredict"
  | "channel_roiPredict"
  | "channel_viewsPredict"
  | "channel_trafficPredict"
  | "size"
  | "channel_size"
  | "firstIntegrationWithChannel"
  | "goodsCategoryCode"
  | "paidSum"
  | "channel_crSite"
  | "channel_cpvPredict"
  | "channel_cpvNewPredict"
  | "channel_roiNewPredict"
  | "prediction_cpv"
  | "prediction_cr"
  | "prediction_ctr"
  | "prediction_roi"
  | "posted_comments"
  | "channel_posted_comments"
  | "tz_id"
  | "tz_link"
  | "tz_name"
  | "tz_user"
  | "tz_goods"
  | "tz_types"
  | "tz_interactive"
  | "tz_positioning"
  | "tz_cta"
  | "tz_authorInFrame"
  | "isPreview"
  | "isPromoWorks"
  | "isTzSelected"
  | "isPromoAndSourceInDirectory"
  | "integration_theme"
  | "payment_phoneOrCard"
  | "payment_totalSumm"
  | "payment_selfEmployedCheckLink"
  | "payment_plannedPaidAt"
  | "integration_paidAt"
  | "integration_paymentStatus"
  | "integration_plannedPaymentDate"
  | "payment_paidAt"
  | "payment_contractBlock_summAttachment"
  | "payment_contractBlock_contract_link"
  | "payment_contractBlock_attachment_link"
  | "guaranteeStatus"
  | "guaranteeRepeat"
  | "ordInfo_hasAct"
  | "goodsCategory"
  | "integration_isServiceProvided"
  | "agreemnt_id"
  | "agreement_channel_name"
  | "agreement_integration_date"
  | "agreement_integration_price"
  | "agreement_channel_price"
  | "agreement_channel_roiNewPredict"
  | "agreement_channel_roiPredict"
  | "agreement_channel_cpvNewPredict"
  | "agreement_channel_cpvPredict"
  | "agreement_channel_audience"
  | "agreement_integration_viewsPredict"
  | "agreement_integration_prediction_roi"
  | "agreement_integration_prediction_roi_new"
  | "agreement_integration_prediction_cpv"
  | "agreement_integration_prediction_cpv_new"
  | "agreement_integration_trafficPredict"
  | "agreement_integration_ctrPredict"
  | "agreement_integration_crPredict"
  | "agreement_channel_minRequests"
  | "agreement_integration_ordersPredict"
  | "agreement_integration_goodsCategory"
  | "agreement_channel_targetAudience"
  | "agreement_channel_13-17"
  | "agreement_channel_18-24"
  | "agreement_channel_25-34"
  | "agreement_channel_35-44"
  | "agreement_channel_45-54"
  | "agreement_channel_55-64"
  | "agreement_channel_allAge"
  | "agreement_channel_man"
  | "agreement_channel_woman"
  | "agreement_channel_ru"
  | "agreement_channel_by"
  | "agreement_channel_ua"
  | "agreement_channel_kz"
  | "agreement_channel_subs"
  | "agreement_channel_notSubs"
  | "agreement_channel_fromPhone"
  | "agreement_channel_fromPC"
  | "agreement_createdAt"
  | "productSampleInfo_id"
  | "productSampleInfo_integration_promokod"
  | "productSampleInfo_channel"
  | "productSampleInfo_userId"
  | "productSampleInfo_refundCost"
  | "productSampleInfo_isSampleRefund"
  | "productSampleInfo_trackNumberRefund"
  | "productSampleInfo_fio"
  | "productSampleInfo_phone"
  | "productSampleInfo_sendAt"
  | "productSampleInfo_sendFrom"
  | "productSampleInfo_comment"
  | "productSampleInfo_sendTo"
  | "productSampleInfo_hasRefund"
  | "productSampleInfo_deliveryCost"
  | "productSampleInfo_productSamples"
  | "productSampleInfo_trackNumberTC"
  | "productSampleInfo_trackNumberCG"
  | "payment_pay_paidAt"
  | "agreement_allDecisionDate"
  | "tz_allowUsage"
  | "govVerification_link"
  | "govVerification_number"
  | "isBotAudience";

const defaultSelections = [
  "id",
  "roi",
  "likes",
  "views",
  "comments",
  "source",
  "channel_roi",
  "lids",
  "canceledOrders",
  "validOrders",
  "averageOrderSumm",
  "payment_id",
  "comment",
  "channel_traffic",
  "channel_canceledOrders",
  "canceledOrders",
  "traffic",
  "minRequests",
  "minOrders",
  "tz_id",
  "payment_totalSumm",
  "productSampleInfo_id",
  "productSampleInfo_trackNumberCG",
  "productSampleInfo_trackNumberTC",
  "productSampleInfo_deliveryCost",
  "productSampleInfo_refundCost",
  "productSampleInfo_trackNumberRefund",
  "productSampleInfo_fio",
  "productSampleInfo_phone",
  "productSampleInfo_sendTo",
];
const simpleInObjectCode = ["goodsCategory", "productSampleInfo_sendFrom"];
const simpleCheckbox = ["productSampleInfo_hasRefund", "productSampleInfo_isSampleRefund"];
const promokod = ["promokod"];
const status = ["status", "channel_status", "integration_paymentStatus"];
const channel = ["channel", "channel_name"];
const channel_id = ["channel_id"];
const links = ["channel_link", "payment_selfEmployedCheckLink", "qrCodeLink"];
const types = ["channelType"];
const releases = ["releases"];
const dates = [
  "dateFound",
  "integration_date",
  "dateGiven",
  "createdAt",
  "paidAt",
  "payment_createdAt",
  "payment_plannedPaidAt",
  "integration_paidAt",
  "integration_plannedPaymentDate",
  "payment_paidAt",
  "statisticDate",
  "productSampleInfo_sendAt",
  "agreement_allDecisionDate",
];
const predictions = [
  "prediction_views",
  "prediction_orders",
  "prediction_traffic",
  "prediction_cpv",
  "prediction_cr",
  "prediction_ctr",
  "prediction_roi",
  "prediction_roi_new",
  "prediction_cpv_new",
];
const releaseUsers = [
  "releaseUser",
  "foundBy",
  "releaseBy",
  "payment_releaseUser",
  "productSampleInfo_userId",
];
const ctrs = [
  "cr",
  "cpv",
  "ctr",
  "channel_ctr",
  "channel_cr",
  "channel_cpv",
  "channel_ctrPredict",
  "channel_roiPredict",
  "channel_viewsPredict",
  "channel_trafficPredict",
  "channel_crSite",
  "channel_cpvPredict",
  "channel_cpvNewPredict",
  "channel_roiNewPredict",
  "channel_crThrough",
  "crThrough",
];
const moneys = [
  "price",
  "initialPrice",
  "channel_price",
  "channel_initialPrice",
  "ordersSumm",
  "cac",
  "channel_cac",
  "paidSum",
];
const contactsEmail = ["agency", "contact"];
const themes = ["theme"];
const goods = ["goods"];
const category = ["category"];
const integrationChannel = ["payment_channel"];
const paymentPrice = [
  "amount",
  "commission",
  "paidSumm",
  "totalSumm",
  "payment_contractSum",
  "payment_contractBlock_summ",
  "payment_contractBlock_summAttachment",
];
const changeNumField = [
  "crSite",
  "ru",
  "by",
  "kz",
  "ua",
  "audience",
  "13-17",
  "18-24",
  "25-34",
  "35-44",
  "45-54",
  "55-64",
  "65_plus",
  "woman",
  "man",
  "targetAudience",
  "subs",
  "notSubs",
  "fromPC",
  "fromPhone",
];
const technicalTask = ["tz"];
const companyAccount = ["companyAccount"];
const integrationPaidSumm = ["integration_paidSumm"];
const size = ["size", "channel_size"];
const firstIntegrationWithChannel = ["firstIntegrationWithChannel"];
const goodsCategoryCode = ["goodsCategoryCode"];
const lastComments = [
  "posted_comments",
  "channel_posted_comments",
  "productSampleInfo_comment",
  "payment_contractBlock_contract_posted_comments",
];
const tzText = ["tz_name", "tz_link"];
const tzSimpleSelect = [
  "tz_cta",
  "tz_interactive",
  "tz_positioning",
  "tz_user",
  "tz_authorInFrame",
];
const tzSelectCheckbox = ["tz_types", "tz_goods"];
const tzCheckbox = ["tz_allowUsage"];
const checkList = [
  "isPreview",
  "isPromoWorks",
  "isTzSelected",
  "isPromoAndSourceInDirectory",
  "isVideoReview",
];
const integrationTheme = ["integration_theme"];
const guarantee = ["guaranteeStatus"];
const contractor = [
  "counteragent_id",
  "counteragent_edo",
  "counteragent_inn",
  "counteragent_operator",
  "counteragent_shortName",
  "payment_counteragent_inn",
  "payment_counteragent_operator",
  "payment_counteragent_shortName",
  "payment_counteragent_edo",
  "payment_counteragent_Id",
];
const payments = [
  "payment_contractBlock_trackNumber",
  "counteragentTrackNumber",
  "payment_contractBlock_isOriginalsReceived",
  "payment_isServiceProvided",
  "payment_counteragent_id",
  "payment_hasAct",
  "payment_paymentType",
  "payment_status",
  "payment_contractBlock_contract_link",
  "payment_contractBlock_attachment_link",
];
const arraysFactory = [
  "payment_pay_id",
  "payment_pay_fio",
  "payment_pay_source",
  "payment_pay_direction",
  "payment_pay_selfEmployedCheck",
  "payment_pay_phoneOrCard",
  "payment_integration_id",
  "payment_channel_contact",
  "payment_pay_status",
  "payment_integration_channel",
  "payment_integration_firstIntegrationWithChannel",
  "payment_pay_totalSumm",
  "payment_pay_plannedPaidAt",
  "payment_pay_paymentLink",
  "payment_integration_price",
  "payment_integration_date",
  "payment_integration_status",
  "payment_integration_promokod",
  "payment_pay_paymentType",
  "ordInfo_hasAct",
  "ordInfo_token",
  "payment_pay_paidAt",
];
const paymentCheckbox = ["integration_isServiceProvided"];
const agrrementsAllCodes = [
  "agreement_id",
  "agreement_channel_name",
  "agreement_integration_date",
  "agreement_integration_price",
  "agreement_integration_initialPrice",
  "agreement_channel_audience",
  "agreement_integration_viewsPredict",
  "agreement_integration_trafficPredict",
  "agreement_integration_ctrPredict",
  "agreement_integration_crPredict",
  "agreement_integration_prediction_roi",
  "agreement_integration_roiNewPredict",
  "agreement_integration_prediction_cpv",
  "agreement_integration_prediction_cpv_new",
  "agreement_channel_minRequests",
  "agreement_integration_ordersPredict",
  "agreement_integration_goodsCategory",
  "agreement_channel_targetAudience",
  "agreement_channel_13-17",
  "agreement_channel_18-24",
  "agreement_channel_25-34",
  "agreement_channel_35-44",
  "agreement_channel_45-54",
  "agreement_channel_55-64",
  "agreement_channel_allAge",
  "agreement_channel_man",
  "agreement_channel_woman",
  "agreement_channel_ru",
  "agreement_channel_by",
  "agreement_channel_ua",
  "agreement_channel_kz",
  "agreement_channel_subs",
  "agreement_channel_notSubs",
  "agreement_channel_fromPhone",
  "agreement_channel_fromPC",
  "agreement_createdAt",
];
const integrationTablePayment = ["integration_ordToken"];
const productSamples = [
  "productSampleInfo_id",
  "productSampleInfo_integration_promokod",
  "productSampleInfo_channel",
  "productSampleInfo_productSamples",
];
const prePayment = [
  "prepayment_id",
  "prepayment_price",
  "prepayment_paymentStatus",
  "prepayment_channel",
  "prepayment_integration_date",
  "prepayment_paidAt",
  "prepayment_releaseUser",
  "prepayment_status",
  "prepayment_paidSum",
];

const botAudience = ["isBotAudience"];

const govVerification = ["govVerification_govLink", "govVerification_govNumber"];

const selectionStretegy = (field: ISettingsSelected) => {
  if (defaultSelections.includes(field.code)) {
    return defaultSelect(field.code as allAvailableField, field);
  } else if (promokod.includes(field.code)) {
    return promokodSelect(field.code as allAvailableField, field);
  } else if (status.includes(field.code)) {
    return statusSelect(field.code as allAvailableField, field);
  } else if (channel.includes(field.code)) {
    return channelSelect(field.code as allAvailableField, field);
  } else if (channel_id.includes(field.code)) {
    return channelIdSelect(field.code as allAvailableField, field);
  } else if (releases.includes(field.code)) {
    return realeseSelect(field.code as allAvailableField, field);
  } else if (dates.includes(field.code)) {
    return datesSelect(field.code as allAvailableField, field);
  } else if (predictions.includes(field.code)) {
    return predictionSelect(field.code as allAvailableField, field);
  } else if (releaseUsers.includes(field.code)) {
    return releaseUsersSelect(field.code as allAvailableField, field);
  } else if (ctrs.includes(field.code)) {
    return ctrsSelect(field.code as allAvailableField, field);
  } else if (moneys.includes(field.code)) {
    return moneySelect(field.code as allAvailableField, field);
  } else if (types.includes(field.code)) {
    return typesSelect(field.code as allAvailableField, field);
  } else if (links.includes(field.code)) {
    return linksSelect(field.code as allAvailableField, field);
  } else if (contactsEmail.includes(field.code)) {
    return contactsEmailSelect(field.code as allAvailableField, field);
  } else if (themes.includes(field.code)) {
    return themeSelect(field.code as allAvailableField, field);
  } else if (goods.includes(field.code)) {
    return goodsSelect(field.code as allAvailableField, field);
  } else if (category.includes(field.code)) {
    return categorySelect(field.code as allAvailableField, field);
  } else if (integrationChannel.includes(field.code)) {
    return integrationChannelSelect(field.code as allAvailableField, field);
  } else if (paymentPrice.includes(field.code)) {
    return paymentPriceSelect(field.code as allAvailableField, field);
  } else if (changeNumField.includes(field.code)) {
    return changeNumFieldSelect(field.code as allAvailableField, field);
  } else if (technicalTask.includes(field.code)) {
    return technicalTaskSelect(field.code as allAvailableField, field);
  } else if (companyAccount.includes(field.code)) {
    return companyAccountSelect(field.code as allAvailableField, field);
  } else if (integrationPaidSumm.includes(field.code)) {
    return integrationPaidSummSelect(field.code as allAvailableField, field);
  } else if (size.includes(field.code)) {
    return sizeSelect(field.code as allAvailableField, field);
  } else if (firstIntegrationWithChannel.includes(field.code)) {
    return firstIntegrationWithChannelSelect(field.code as allAvailableField, field);
  } else if (goodsCategoryCode.includes(field.code)) {
    return goodsCategoryCodeSelect(field.code as allAvailableField, field);
  } else if (lastComments.includes(field.code)) {
    return lastCommentsSelect(field.code as allAvailableField, field);
  } else if (tzSimpleSelect.includes(field.code)) {
    return tzSimpleStrategySelect(field.code as allAvailableField, field);
  } else if (tzText.includes(field.code)) {
    return tzTextSelect(field.code as allAvailableField, field);
  } else if (tzSelectCheckbox.includes(field.code)) {
    return tzSelectCheckboxStrategy(field.code as allAvailableField, field);
  } else if (checkList.includes(field.code)) {
    return checkListStrategy(field.code as allAvailableField, field);
  } else if (contractor.includes(field.code)) {
    return contractorStrategy(field.code as allAvailableField, field);
  } else if (integrationTheme.includes(field.code)) {
    return integrationThemeStrategy(field.code as allAvailableField, field);
  } else if (payments.includes(field.code)) {
    return paymentsStrategy(field.code as allAvailableField, field);
  } else if (arraysFactory.includes(field.code)) {
    return arraysStrategy(field.code as allAvailableField, field);
  } else if (guarantee.includes(field.code)) {
    return guaranteeStrategy(field.code as allAvailableField, field);
  } else if (simpleInObjectCode.includes(field.code)) {
    return simpleInObjectCodeStrategy(field.code as allAvailableField, field);
  } else if (paymentCheckbox.includes(field.code)) {
    return paymentCheckboxStrategy(field.code as allAvailableField, field);
  } else if (agrrementsAllCodes.includes(field.code)) {
    return agrrementsAllCodesStrategy(field.code as allAvailableField, field);
  } else if (integrationTablePayment.includes(field.code)) {
    return integrationTablePaymentsStrategy(field.code as allAvailableField, field);
  } else if (simpleCheckbox.includes(field.code)) {
    return simpleCheckboxStrategy(field.code as allAvailableField, field);
  } else if (productSamples.includes(field.code)) {
    return productSampleStrategy(field.code as allAvailableField, field);
  } else if (tzCheckbox.includes(field.code)) {
    return tzCheckboxStrategy(field.code as allAvailableField, field);
  } else if (prePayment.includes(field.code)) {
    return prePaymentStrategy(field.code as allAvailableField, field);
  } else if (govVerification.includes(field.code as allAvailableField)) {
    return govVerificationStrategy(field.code as allAvailableField, field);
  } else if (botAudience.includes(field.code as allAvailableField)) {
    return botAudienceStrategy(field.code as allAvailableField, field);
  }
};

export { selectionStretegy };

