import { FC, useState, useEffect, useMemo } from "react";
import { IChannelCardPage } from "./models/IChannelCardPage";
import { Box, Button, CircularProgress, Drawer, Skeleton, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import userStore from "../../store/user.store";
import { CHANNELS_ROUTES, ROUTES } from "../../shared/routes/routes";
import ChannelService from "../../services/channel/channel.service";
import { AxiosError } from "axios";
import { IChannelFullResponse } from "../../services/channel/ICnannelResponse";
import { AsideCardComments } from "../TableAsidePage/ui/AsideCardComments/AsideCardComments";
import { AsideCardIntegrations } from "./ui/AsideCardIntegrations/AsideCardIntegrations";
import { IPostedCommentItem } from "../../components/PostedCommentItem/models/IPostedCommentItem";
import { ICreateCommentsRequest } from "../../services/common/ICommonRequest";
import CommonService from "../../services/common/common.service";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { ChannelAsideRequisite } from "./ui/ChannelAsideRequisite/ChannelAsideRequisite";
import { ChannelAsidePredict } from "./ui/ChannelAsidePredict/ChannelAsidePredict";
import { ChannelAsideStatistic } from "./ui/ChannelAsideStatistic/ChannelAsideStatistic";
import { ALL_ROLES, ALL_RULES } from "../../shared/rules/interface/EnumRights";
import { ChannelAsideMain } from "./ui/ChannelAsideMain/ChannelAsideMain";
import channelCardStore from "../../store/channelCard.store";
import channelTableStore from "../../store/channelTable.store";
import { ChannelAudienceEdit } from "./ui/ChannelAudienceEdit/ChannelAudienceEdit";
import { getAudienceDataOnChannelType } from "./ui/ChannelAudience/shared/functions";
import { LS_SAVE_WIDTH_CHANNEL } from "../../shared/constants/localstorage";
import { ResizeButton } from "../../components/ResizeButton/ResizeButton";
import { useResizeHook } from "../../hooks/useResizeHook";
import { CustomIconButton } from "../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { ChannelAsideVerified } from "./ui/ChannelAsideVerified/ChannelAsideVerified";
import { AsideTabs } from "../TableAsidePage/ui/AsideTabs/AsideTabs";
import { AsideCardLayout } from "../../components/AsideCardLayout/AsideCardLayout";
import { ChannelAsideSentSamples } from "./ui/ChannelAsideSentSamples/ChannelAsideSentSamples";

export const ChannelCardPage: FC<IChannelCardPage> = observer((props) => {
  const { channelId, onClose } = props;

  const { id } = useParams();

  const navigate = useNavigate();

  const urlId = channelId ? channelId : id;

  const [isOpenPage, setOpenPage] = useState(false);
  const [data, setData] = useState<null | IChannelFullResponse>(null);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState<string | null>(null);

  const [channelName, setChannelName] = useState("");
  const [comments, setComments] = useState<IPostedCommentItem[]>([]);
  const [update_id, setUpdateId] = useState<number | null>(null);
  const [subPage, setSubPage] = useState(0);
  const channelCardTabs = [
    { name: "Общее", value: 0 },
    { name: "Отправленные образцы", value: 1 },
  ];

  const getFullData = (id: string) => {
    setLoading(true);

    ChannelService.getChannelFull(id)
      .then((res) => {
        // console.log(res, 'res full data channel card')
        channelCardStore.setCrSite(res.data.crSite);
        channelCardStore.setMinRequests(Math.round(res.data.minRequests || 0));
        channelCardStore.setMinOrders(Math.round(res.data.minOrders || 0));
        channelCardStore.setRoiPredict(res.data.roiPredict);
        channelCardStore.setViewsPredict(res.data.viewsPredict);
        channelCardStore.setCpvPredict(res.data.cpvPredict);
        channelCardStore.setCtrPredict(res.data.ctrPredict);
        channelCardStore.setInitialPrice(res.data.initialPrice);
        channelCardStore.setCpvNewPredict(res.data.cpvNewPredict);
        channelCardStore.setRoiNewPredict(res.data.roiNewPredict);
        channelCardStore.setPrice(res.data.price);
        channelCardStore.setTrafficPredict(res.data.trafficPredict);
        channelCardStore.setChannelSize(res.data?.size?.name || null);
        channelCardStore.setBanned(res.data.isBanned);

        channelCardStore.setStatisticDate(res?.data?.statisticDate);
        channelCardStore.setTargetAudience(res.data?.targetAudience);
        channelCardStore.setAllAge(res.data?.allAge);

        channelCardStore.setAudienceData(getAudienceDataOnChannelType(res.data));

        channelCardStore.setPaymentOnCard(res.data.isPaymentOnCard);
        channelCardStore.setGovVerification(res.data.govVerification);

        setComments(res.data.posted_comments);
        setChannelName(res.data.name);
        setData(res.data);
        setUpdateId(res.data.update_id);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err full data card channel')
          setError(err.message);
          setLoading(false);
        }
      });
  };

  const addComment = (value: string) => {
    if (userStore?.user?.id) {
      const option: ICreateCommentsRequest = {
        toEntity: "channel",
        entity_id: +urlId!,
        text: value,
      };

      CommonService.createComments(option)
        .then((res) => {
          const item: IPostedCommentItem = {
            channelId: +urlId!,
            id: res.data.id,
            postedAt: res.data.postedAt,
            postedById: userStore.user?.id!,
            text: value,
            type: "user_comment",
          };
          channelTableStore.addLastComment(+urlId!, res.data);
          // console.log(res, "res comment add data")
          setComments((prev) => [...prev, item]);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, "err add comment")
          }
        });
    }
  };

  const checkUserEdit = (): boolean => {
    if (userStore?.user?.role === ALL_ROLES.ADMIN) {
      return true;
    } else if (
      userStore.user?.rights.find((x) => x.code === ALL_RULES.edit_channels) &&
      channelCardStore.isBanned === false
    ) {
      return true;
    }
    if (userStore.user?.id === data?.foundBy?.id && channelCardStore.isBanned === false) {
      return true;
    } else return false;
  };

  const closeModal = () => {
    setOpenPage(false);
    channelCardStore.setChatOpen(true);

    setTimeout(() => {
      if (onClose) {
        onClose();
      } else {
        navigate("/" + userStore.currentCompany + "/" + ROUTES.CHANNELS);
      }

      channelCardStore.onResetStore();
    }, 200);
  };

  const navigateCreateIntegration = () =>
    window.open(
      "/" +
        userStore.currentCompany +
        "/" +
        ROUTES.CHANNELS +
        "/" +
        CHANNELS_ROUTES.MAIN +
        `/${urlId}/` +
        CHANNELS_ROUTES.INTEGRATION
    );

  useEffect(() => {
    if (urlId) {
      setOpenPage(true);
      getFullData(urlId);
      channelCardStore.setChatOpen(true);
    }
  }, [urlId]);

  const [onMouseDown, onMouseMove, onMouseUp, startResize] = useResizeHook(
    "channel-content",
    LS_SAVE_WIDTH_CHANNEL
  );

  return (
    <Drawer
      open={isOpenPage}
      onClose={closeModal}
      anchor="right"
      PaperProps={{ sx: { backgroundColor: "#F2F3F4" } }}
      slotProps={{ backdrop: { sx: { left: 70 } } }}
      ModalProps={{ sx: { left: 70 } }}
      onMouseMove={startResize ? onMouseMove : undefined}>
      <Box
        id={"channel-content"}
        sx={{
          p: 2,
          position: "relative",
          width: {
            xl: localStorage.getItem(LS_SAVE_WIDTH_CHANNEL)
              ? `calc(${localStorage.getItem(LS_SAVE_WIDTH_CHANNEL) + "vw"} - 70px)`
              : "85vw",
            lg: localStorage.getItem(LS_SAVE_WIDTH_CHANNEL)
              ? `calc(${localStorage.getItem(LS_SAVE_WIDTH_CHANNEL) + "vw"} - 70px)`
              : "calc(100vw - 70px - 100px)",
            md: localStorage.getItem(LS_SAVE_WIDTH_CHANNEL)
              ? `calc(${localStorage.getItem(LS_SAVE_WIDTH_CHANNEL) + "vw"} - 70px)`
              : "calc(100vw - 70px - 50px)",
            sm: "calc(100vw - 70px)",
            xs: "100vw",
          },
          minWidth: {
            xl: "85vw",
            lg: "calc(100vw - 70px - 100px)",
            md: "calc(100vw - 70px - 50px)",
            sm: "calc(100vw - 70px)",
            xs: "100vw",
          },
        }}>
        <ResizeButton
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          sx={{ display: { xs: "none", md: "block" } }}
        />
        {isLoading && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 30 }}>
            <CircularProgress sx={{ minHeight: 200, minWidth: 200 }} />
          </Box>
        )}
        {isError && (
          <Box>
            <Typography variant="h4" color={"error"} align="center">
              {isError}
            </Typography>
            <Typography variant="h5" color={"action"} align="center" sx={{ mt: 1 }}>
              Страница может больше не существовать или быть перемещена на другой веб адрес.
            </Typography>
            <Button fullWidth variant="outlined" onClick={closeModal} sx={{ mt: 2 }}>
              Вернуться назад
            </Button>
          </Box>
        )}
        {data && !isLoading && urlId && (
          <>
            <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1, mt: "-6px" }} spacing={1}>
              <CustomIconButton onClick={closeModal} sx={{ ml: -1 }}>
                <KeyboardArrowLeft fontSize="large" />
              </CustomIconButton>
              <Typography variant="h5" color={channelCardStore?.isBanned ? "error" : "primary"}>
                Канал №{urlId}, {channelName}
              </Typography>
            </Stack>
            {!isError && (
              <Box sx={{ mb: 2 }}>
                {!isLoading ? (
                  <AsideTabs
                    initValue={subPage}
                    setValue={setSubPage as any}
                    tabs={channelCardTabs}
                  />
                ) : (
                  <Stack direction={"row"} alignItems={"center"} spacing={2} sx={{ mt: 1 }}>
                    {channelCardTabs.map((item) => (
                      <Skeleton key={item.value} variant="rounded" height={24} width={90} />
                    ))}
                  </Stack>
                )}
              </Box>
            )}
            {subPage === 0 && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    lg: `${channelCardStore.chatOpen ? "66%" : "93.5%"} ${
                      channelCardStore.chatOpen ? "32.5%" : "5%"
                    }`,
                    xs: "100%",
                  },
                  columnGap: 2,
                  rowGap: 2,
                  transition: "all ease .3s",
                }}>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      display: "grid",
                      justifyContent: "space-between",
                      gridTemplateColumns: { xs: "100%", md: "50% 48%" },
                      columnGap: 2,
                      rowGap: 2,
                    }}>
                    {update_id && (
                      <ChannelAsideMain
                        urlId={+urlId}
                        data={data}
                        update_id={update_id}
                        setUpdateId={setUpdateId}
                        channelName={channelName}
                        setChannelName={setChannelName}
                        readOnly={!checkUserEdit()}
                      />
                    )}
                    {update_id && (
                      <Box>
                        <ChannelAudienceEdit
                          channelId={+urlId}
                          update_id={update_id}
                          onChangeUpdateId={setUpdateId}
                          readOnly={!checkUserEdit()}
                          type={data?.type?.code}
                        />
                        {data.accounts && (
                          <ChannelAsideRequisite
                            channelId={+urlId}
                            accounts={data.accounts}
                            readOnly={!checkUserEdit()}
                          />
                        )}
                        {channelCardStore.govVerification && (
                          <ChannelAsideVerified
                            channelId={+urlId}
                            audience={channelCardStore.audienceData?.audience || 0}
                            govVerification={channelCardStore.govVerification}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  {update_id && (
                    <ChannelAsidePredict
                      id={+urlId}
                      update_id={update_id}
                      onChangeUpdateId={setUpdateId}
                      readOnly={!checkUserEdit()}
                    />
                  )}
                  <AsideCardIntegrations
                    isBanned={data.isBanned}
                    integrationsWithStats={data.integrationsWithStats}
                    onClick={navigateCreateIntegration}
                    readOnly={!checkUserEdit()}
                  />
                  {update_id && data && (
                    <ChannelAsideStatistic
                      id={+urlId}
                      update_id={update_id}
                      onChangeUpdateId={setUpdateId}
                      data={data}
                      readOnly={!checkUserEdit()}
                    />
                  )}
                </Stack>
                <Box>
                  <AsideCardComments
                    showResizeBtn
                    onCloseChat={() => channelCardStore.setChatOpen(false)}
                    onOpenChat={() => channelCardStore.setChatOpen(true)}
                    sxBtnResize={{ display: { xs: "none", lg: "block" } }}
                    comments={comments}
                    addComment={addComment}
                    sx={{ maxHeight: { lg: "560px", xs: "300px" } }}
                    readOnly={!checkUserEdit()}
                    entity="channel"
                    onChangeComments={setComments}
                    entityId={+urlId}
                    onAddLastComment={channelTableStore.addLastComment}
                    onUpdateCommentsArray={channelTableStore.onUpdateCommentsArray}
                  />
                </Box>
              </Box>
            )}
            {subPage === 1 && <ChannelAsideSentSamples id={+urlId} />}
          </>
        )}
      </Box>
    </Drawer>
  );
});
