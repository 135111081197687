import { IUser } from "../../../entities/user/models/IUser";
import { IIntegrationsGuaranteeResponse } from "../../../services/integration/IIntegrationsResponse";
import {
  IFromToArray,
  IIntegrationUrlFilters,
} from "../../../services/integration/IntegrationsRequest";
import { ILayoutResponse } from "../../../services/layout/layoutResponse";
import {
  IGoodCategoriesItem,
  IReferencesTzResponse,
  IRequisteAccount,
} from "../../../services/references/IReferencesResponse";
import { ALL_ROLES } from "../../../shared/rules/interface/EnumRights";
import { IFilterSelectItem } from "../../Filters/UI/FilterSelectChip/IFilterSelectChip";
import { IStatusesPayments } from "../../Statuses/models/ISettingsFilters";
import { IGoodItem } from "../../Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem";

export const getGoodsFilterItems = (initGoods: IGoodItem[]): IFilterSelectItem[] => {
  const goods: IFilterSelectItem[] = initGoods.map((good) => {
    return { name: good.name, value: good.article, filterCode: good?.category?.name };
  });

  return goods;
};

export const getSocialTypeFiltes = (
  socialStore: ILayoutResponse["channelTypes"]
): IFilterSelectItem[] => {
  const socialKeys: string[] = Object.keys(socialStore);

  const social: IFilterSelectItem[] = socialKeys.map((key) => {
    const item: IFilterSelectItem = {
      name: socialStore[key].name,
      value: key,
      iconUrl: socialStore[key]?.icon,
    };
    return item;
  });

  return social;
};

export const getManagerFilterItems = (allUsers: IUser[]): IFilterSelectItem[] => {
  const filterArrays = [
    ALL_ROLES.trainee,
    ALL_ROLES.RELEASE_MANAGER,
    ALL_ROLES.SEARCH_MANAGER,
    ALL_ROLES.PRE_HEAD_MANAGER,
    ALL_ROLES.SEARCH_HEAD_MANAGER,
  ];

  const managers: any[] = allUsers
    .filter((user) => user.isActive === true)
    .map((user) => {
      if (filterArrays.includes(user.role)) {
        const manager: IFilterSelectItem = {
          name: `${user.firstName} ${user.lastName}`,
          value: `${user.id}`,
        };
        return manager;
      } else return undefined;
    })
    .filter((item) => item !== undefined);

  return managers;
};

export const getChannelSizeFilterItems = (sizes: IRequisteAccount[]): IFilterSelectItem[] => {
  return sizes.map((x) => {
    return { name: x?.name, value: x?.code };
  });
};

export const getAllTz = (allTz: IReferencesTzResponse[]): IFilterSelectItem[] => {
  const tzInSelect: IFilterSelectItem[] = allTz.map((item) => {
    const newItem: IFilterSelectItem = {
      name: item.name,
      value: item.id.toString(),
    };
    return newItem;
  });

  return tzInSelect;
};

export const getAllGoodsCategories = (
  allGoodsCategories: IGoodCategoriesItem[]
): IFilterSelectItem[] => {
  const tzInSelect: IFilterSelectItem[] = allGoodsCategories.map((item) => {
    const newItem: IFilterSelectItem = {
      name: item.name,
      value: item.code,
    };
    return newItem;
  });

  return tzInSelect;
};

export const getAllPaymentStatus = (statusPayments: IStatusesPayments): IFilterSelectItem[] => {
  const statusesKey: string[] = Object.keys(statusPayments);

  const statuses: IFilterSelectItem[] = statusesKey.map((key) => {
    const item: IFilterSelectItem = {
      name: statusPayments[key].name,
      value: key,
    };
    return item;
  });

  return statuses;
};

export const getFilterIntegrationQuery = (searchQuery: URLSearchParams): IIntegrationUrlFilters => {
  const transormValueToArrayFromTo = (
    searchArr: string[],
    isDate?: boolean
  ): IFromToArray<number | string> => {
    const array = !isDate ? searchArr.map((x) => +x) : searchArr;

    const fromToArray: IFromToArray<number | string> = {
      from: array[0],
      to: array[1],
    };
    return fromToArray;
  };

  const option: IIntegrationUrlFilters | any = {
    id:
      searchQuery
        .get("id")
        ?.split(",")
        .map((x) => +x) || null,
    roi: searchQuery.get("roi")
      ? transormValueToArrayFromTo(searchQuery.get("roi")?.split(",")!)
      : null,
    cpv: searchQuery.get("cpv")
      ? transormValueToArrayFromTo(searchQuery.get("cpv")?.split(",")!)
      : null,
    cr: searchQuery.get("cr")
      ? transormValueToArrayFromTo(searchQuery.get("cr")?.split(",")!)
      : null,
    crThrough: searchQuery.get("crThrough")
      ? transormValueToArrayFromTo(searchQuery.get("crThrough")?.split(",")!)
      : null,
    ctr: searchQuery.get("ctr")
      ? transormValueToArrayFromTo(searchQuery.get("ctr")?.split(",")!)
      : null,
    "promokod.code": searchQuery.get("promokod") || null,
    "channel.type.code": searchQuery.get("channelType")?.split(",") || null,
    "channel.id": searchQuery.get("channel")
      ? JSON.parse(searchQuery.get("channel")!).map((x: IFilterSelectItem) => x.value)
      : null,
    "goodsCategory.code": searchQuery.get("goodsCategory")?.split(",") || null,
    "tz.id":
      searchQuery
        .get("tz")
        ?.split(",")
        .map((x) => +x) || null,
    "goods.article": searchQuery.get("goods")?.split(",") || null,
    likes: searchQuery.get("likes")
      ? transormValueToArrayFromTo(searchQuery.get("likes")?.split(",")!)
      : null,
    comments: searchQuery.get("comments")
      ? transormValueToArrayFromTo(searchQuery.get("comments")?.split(",")!)
      : null,
    views: searchQuery.get("views")
      ? transormValueToArrayFromTo(searchQuery.get("views")?.split(",")!)
      : null,
    validOrders: searchQuery.get("orders")
      ? transormValueToArrayFromTo(searchQuery.get("orders")?.split(",")!)
      : null,
    canceledOrders: searchQuery.get("canceledOrders")
      ? transormValueToArrayFromTo(searchQuery.get("canceledOrders")?.split(",")!)
      : null,
    traffic: searchQuery.get("traffic")
      ? transormValueToArrayFromTo(searchQuery.get("traffic")?.split(",")!)
      : null,
    prediction_orders: searchQuery.get("prediction_orders")
      ? transormValueToArrayFromTo(searchQuery.get("prediction_orders")?.split(",")!)
      : null,
    prediction_traffic: searchQuery.get("prediction_traffic")
      ? transormValueToArrayFromTo(searchQuery.get("prediction_traffic")?.split(",")!)
      : null,
    prediction_views: searchQuery.get("prediction_views")
      ? transormValueToArrayFromTo(searchQuery.get("prediction_views")?.split(",")!)
      : null,
    releaseUserId: searchQuery.get("releaseUserId")?.split(",") || null,
    "channel.foundById": searchQuery.get("foundById")?.split(",") || null,
    integration_date: searchQuery.get("integration_date")
      ? transormValueToArrayFromTo(searchQuery.get("integration_date")?.split(",")!, true)
      : null,
    "payment.paidAt": searchQuery.get("paidAt")
      ? transormValueToArrayFromTo(searchQuery.get("paidAt")?.split(",")!, true)
      : null,
    "status.code": searchQuery.get("status")?.split(",") || null,
    "channel.theme.code": searchQuery.get("channelTheme")?.split(",") || null,
    firstIntegrationWithChannel: searchQuery.get("firstIntegrationWithChannel")
      ? searchQuery.get("firstIntegrationWithChannel") === "true"
      : null,
    price: searchQuery.get("price")
      ? transormValueToArrayFromTo(searchQuery.get("price")?.split(",")!)
      : null,
    "payment.paidSum": searchQuery.get("paidSum")
      ? transormValueToArrayFromTo(searchQuery.get("paidSum")?.split(",")!)
      : null,
    "payment.plannedPaidAt": searchQuery.get("plannedPaidAt")
      ? transormValueToArrayFromTo(searchQuery.get("plannedPaidAt")?.split(",")!, true)
      : null,
    "payment.status.code": searchQuery.get("paymentStatus")?.split(",") || null,
    "channel.size.code": searchQuery.get("size")?.split(",") || null,
    "guarantee.status.code":
      searchQuery.get("guaranteeNotCompleted") === "true" ? "not-completed" : null,
    "guarantee.id": searchQuery.get("hasGuarantee") ? "notNull" : null,
    isVideoReview: searchQuery.get("isVideoReview")
      ? searchQuery.get("isVideoReview") === "true"
      : null,
    isPaymentOnCard: searchQuery.get("isPaymentOnCard")
      ? searchQuery.get("isPaymentOnCard") === "true"
      : null,
    "channel.govVerification.isVerifiedByGov": searchQuery.get("isVerifiedByGov")
      ? searchQuery.get("isVerifiedByGov") === "true"
      : null,
    "channel.govVerification.number":
      searchQuery.get("isVerifiedByGov") === "notNull" ? "notNull" : null,
    "channel.govVerification.link":
      searchQuery.get("isVerifiedByGov") === "notNull" ? "null" : null,
  };

  const filterEmptyFieldOption: any = {};

  for (let key in option) {
    if (option[key] || option[key] === false) {
      filterEmptyFieldOption[key] = option[key];
    }
  }

  return filterEmptyFieldOption;
};

export const getIntegrationTableItemColor = (
  guarantee: IIntegrationsGuaranteeResponse | null,
  views: number,
  integrationDate: Date,
  statusCode: string
) => {
  let currentColor = "fff";

  if (
    !guarantee ||
    !guarantee.status ||
    !guarantee.lastDate ||
    !guarantee.viewsCount ||
    !statusCode
  ) {
    return currentColor;
  }

  if (statusCode !== "release") {
    return currentColor;
  }

  const code = guarantee?.status.code;
  const viewsCount = guarantee?.viewsCount;

  const parsedDate = new Date(integrationDate);
  const sixteenDaysAgo = new Date(parsedDate);
  const currentDate = new Date();
  sixteenDaysAgo.setDate(parsedDate.getDate() + 16);

  if (code === "not-completed") {
    currentColor = "#ffcece";
  }

  if (currentDate >= sixteenDaysAgo && code === "wait") {
    if (views < viewsCount * 0.5) {
      currentColor = "#fff6c8";
    }
  }

  return currentColor;
};
