import { makeAutoObservable } from "mobx";
import { TUpdateUser } from "../entities/user/models/IUser";
import UsersService from "../services/users/users.service";
import AllUsersStore from "./AllUsers.store";
import userStore from "./user.store";
import { AxiosError } from "axios";

class UpdateUserStore {
  user: TUpdateUser | null = null;
  openModal: boolean = false;
  imageUrl: string | null = null;
  constructor() {
    makeAutoObservable(this);
  }

  setImageUrl = (url: string | null) => {
    this.imageUrl = url;
  };

  setUpdatedUser = (user: TUpdateUser) => {
    this.user = { ...user };
  };

  setUserFiled<K extends keyof TUpdateUser>(key: K, value: TUpdateUser[K]) {
    if (this.user !== null) {
      this.user[key] = value;
    }
  }

  setOpenModal = (value: boolean) => {
    this.openModal = value;
  };

  update = () => {
    if (!this.user) {
      return;
    }

    return UsersService.updateUser(this.user)
      .then((res) => AllUsersStore.updateUser(res.data))
      .catch((err) => {
        throw err;
      });
  };

  updateUserAvatar = () => {
    const option = {
      imageUrl: this.imageUrl,
    };
    return UsersService.updateAvatar(option)
      .then((res) => {
        userStore.setAvatar(res.data.imageUrl);
        this.setImageUrl(null);
      })
      .catch((err) => {
        if (err instanceof AxiosError) throw err.response?.data.message;
      });
  };

  clearUserFields = () => {
    this.user = null;
  };
}

export default new UpdateUserStore();
