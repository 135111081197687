import { Box, Skeleton } from "@mui/material";
import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { IReferencesPresetPropsRequest } from "../../../../services/references/IReferencesRequest";
import ReferencesService from "../../../../services/references/references.service";
import { LS_TAKE_REQUEST_CHANNEL } from "../../../../shared/constants/localstorage";
import channelTableStore from "../../../../store/channelTable.store";
import presetFiltersStore from "../../../../store/presetFilters.store";
import userStore from "../../../../store/user.store";
import { FilterPresetItem } from "../../../Filters/UI/FilterPresetItem/FilterPresetItem";
import { IPresetList } from "../../../IntegrationTable/models/IPresetList";
import { getFilterChannelQuery } from "../ChannelFiter/shared/dataList";
import { checkRemovePresetRole } from "../ChannelFiter/shared/functions";

import { ICoolIntegrationRequest } from "../../../../services/integration/IntegrationsRequest";
import IntegrationsService from "../../../../services/integration/integrations.service";
import allFilterListJson from "../../../../shared/mock/filters.mock.json";

export const ChannelPresetList: FC<IPresetList> = observer((props) => {
  const { onFilterClose } = props;

  const [isLoading, setLoading] = useState(false);

  const getFiltersTableItems = (urlQuery: URLSearchParams) => {
    channelTableStore.setLoading(true);

    const take = localStorage.getItem(LS_TAKE_REQUEST_CHANNEL)
      ? localStorage.getItem(LS_TAKE_REQUEST_CHANNEL)
      : 100;

    const option: ICoolIntegrationRequest = {
      filter: getFilterChannelQuery(urlQuery),
      chunk: 0,
      callBackType: "items",
      mainAlias: "channel",
      orderBy: [
        {
          field: "id",
          direction: "DESC",
        },
      ],
      take: +`${take}`,
    };

    IntegrationsService.getCoolIntegrations(option)
      .then((res) => {
        // console.log(res, "res confirm filters")

        const allSearchParamsUrl: string[] = [];

        for (let value of urlQuery.keys() as any) {
          allSearchParamsUrl.push(value);
        }

        const filtersPreset: any[] = allFilterListJson.channelAllFilters.filter((x) =>
          allSearchParamsUrl.includes(x.searchUrl)
        );
        channelTableStore.setChannelPresetFilters(filtersPreset);

        const allFiltersPreset: any[] = allFilterListJson.channelAllFilters.map((x) => {
          if (allSearchParamsUrl.includes(x.searchUrl)) {
            return { ...x, isSelected: true };
          } else return { ...x, isSelected: false };
        });
        channelTableStore.setChanneAllPresetFilters(allFiltersPreset);

        const mappedItems = res.data.items.map((item) => ({
          ...item,
          foundBy: { id: item.foundById },
          releaseBy: item.releaseById ? {
            id: item.releaseById,
          } : null,
          govLink: item.govVerification.link,
          govNumber: item.govVerification.number,
        }));

        channelTableStore.setTableItems(mappedItems);
        getTotalCount(urlQuery);

        channelTableStore.setPage(1);
        channelTableStore.setLoading(false);
        onFilterClose();
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, "err filter confirm")
          channelTableStore.setLoading(false);
        }
      });
  };

  const getTotalCount = async (urlQuery: URLSearchParams) => {
    const params: ICoolIntegrationRequest = {
      filter: getFilterChannelQuery(urlQuery),
      chunk: 0,
      callBackType: "count",
      mainAlias: "channel",
      orderBy: [
        {
          field: "id",
          direction: "DESC",
        },
      ],
      take: 100,
    };
    try {
      const res = await IntegrationsService.getCoolIntegrations(params);
      // console.log(res, 'res integr table DATA')
      channelTableStore.setTotalCount(res.data.count);
    } catch (err) {
      if (err instanceof AxiosError) {
        // console.log(err, 'err interg data')
      }
    }
  };

  const getIntegrationsPreset = () => {
    if (userStore.user) {
      setLoading(true);

      const option: IReferencesPresetPropsRequest = {
        entity: "channel",
        userId: userStore.user?.id,
      };

      ReferencesService.getPresetWithProps(option)
        .then((res) => {
          // console.log(res, "res channel preset")
          presetFiltersStore.setAllPresetChannels(res.data);
          setLoading(false);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, "err channel preset")
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    if (Array.isArray(presetFiltersStore.presetChannels) === false) {
      getIntegrationsPreset();
    }
  }, []);

  return (
    <Box>
      {!isLoading && (
        <Box
          sx={{
            display: "grid",
            columnGap: 1,
            rowGap: 1,
            gridTemplateColumns: "repeat(auto-fill, minmax(125px, max-content))",
            ml: "30px",
          }}>
          {presetFiltersStore.presetChannels &&
            presetFiltersStore.presetChannels.map((item) => (
              <FilterPresetItem
                key={item.id}
                {...item}
                onClick={getFiltersTableItems}
                onDelete={presetFiltersStore.deletePresetChannels}
                readOnly={!checkRemovePresetRole(item.user)}
              />
            ))}
        </Box>
      )}
      {isLoading && presetFiltersStore.presetChannels?.length === 0 && (
        <Skeleton variant="rounded" />
      )}
    </Box>
  );
});
