import { IStrategyField } from "../../../../../strategy/interface/IStrategyField";

export const sentSamplesTableRow: IStrategyField[] = [
  {
    title: "Дата отправки",
    code: "sendAt",
    immutable: false,
    isSimple: false,
    isDate: true,
    isSort: true,
    readOnly: false,
    isFullYears: true,
    isDateEdit: false,
    isIntegrationDatesEdit: false,
    isIntegrationPaymentsDateEdit: false,
  },
  {
    title: "Образцы товаров / Цвет",
    code: "integration",
    fieldCode: "productSamples",
    immutable: false,
    isSimple: false,
    isChannelProductSample: false,
    isPromokodProductSample: false,
    isProductSampleList: true,
  },
  {
    title: "ID интеграции / Промокод",
    code: "integration",
    fieldCode: "promokod",
    immutable: false,
    isSimple: false,
    isChannelProductSample: false,
    isPromokodProductSample: true,
    isProductSampleList: false,
  },
];
