import { FC, useEffect, useState } from "react";
import { IIntegrationOplataPage } from "./IIntegrationOplataPage";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { CreateOplata } from "./ui/CreateOplata/CreateOplata";
import integrationPageStore from "../../../../store/integrationPage.store";
import { OplataFullBlock } from "./ui/OplataFullBlock/OplataFullBlock";
import IntegrationsService from "../../../../services/integration/integrations.service";
import { AxiosError } from "axios";
import { checkNumberBoolean } from "../../../../shared/functions/functions";
import { CustomButton } from "../../../../components/UI/Buttons/CustomButton/CustomButton";
import { transformPaysData } from "../../../FinanceCardPage/shared/dataList";

export const IntegrationOplataPage: FC<IIntegrationOplataPage> = observer((props) => {
  const { isReadOnly, setPriceValue, setUpdateId, update_id, id, onChangeCpv } = props;

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [isCreatePayments, setCreatePayments] = useState(false);

  const getApproveThisIntegrations = () => {
    setLoading(true);
    setError(null);

    IntegrationsService.approvedIntegration(id, true)
      .then((res) => {
        integrationPageStore.setLastPayInfo(res.data?.channel?.lastPayInfo || null);
        integrationPageStore.setCeatedAt(res.data?.payment?.createdAt || null);

        integrationPageStore.setPlannedPaidAt(res.data?.payment?.plannedPaidAt || null);
        integrationPageStore.setCounteragent(res.data?.payment?.counteragent || null);
        integrationPageStore.setIntegrationContacts(res.data?.channel?.contact, false);
        integrationPageStore.setIntegrationsIds(
          res.data?.payment?.integrations || null,
          res.data.id
        );
        integrationPageStore.setContract(res.data?.payment?.contract || null);
        integrationPageStore.setAttachment(res.data?.payment?.attachment || null);
        integrationPageStore.setSupplyContract(res.data?.payment?.supplyContract || null);
        integrationPageStore.setOplataId(res.data?.payment?.id || null);
        integrationPageStore.setPaymentType(res.data?.payment?.paymentType || null);
        integrationPageStore.setStatus(res.data?.payment?.status || null);
        integrationPageStore.setPays(transformPaysData(res.data?.payment?.pays || []));
        integrationPageStore.setPaidAt(res.data?.payment?.paidAt || null);
        integrationPageStore.setOplataBlocked(
          res.data?.payment?.status?.code === "cancel" ? true : false
        );
        integrationPageStore.setPostedComments(res.data?.payment?.posted_comments || null);
        integrationPageStore.setHasSelfEmployedCheck(
          res.data?.payment?.hasSelfEmployedCheck || null
        );
        integrationPageStore.setSelfEmployedCheckLink(
          res.data?.payment?.selfEmployedCheckLink || null
        );
        integrationPageStore.setActLink(res.data?.payment?.actLink || null);
        integrationPageStore.setHasAct(res.data?.payment?.hasAct || null);
        integrationPageStore.setServiceProvided(res.data?.payment?.isServiceProvided || null);
        integrationPageStore.setIntegrationId(res.data?.id);
        integrationPageStore.setTrackNumber(res.data.payment?.trackNumber || null);
        integrationPageStore.setCounteragentTrackNumber(
          res.data.payment?.counteragentTrackNumber || null
        );
        integrationPageStore.setIntegrations(res.data?.payment?.integrations || null);
        integrationPageStore.setIsCheckGuaranteeCompleteAll(
          res.data?.payment?.isCheckGuaranteeCompleteAll || null
        );

        if (integrationPageStore?.isEmpyPaymet) {
          integrationPageStore.setTotalSumm(res.data?.price || null);
        } else {
          integrationPageStore.setTotalSumm(res.data?.payment?.totalSumm || null);
          integrationPageStore.setPaymentUpdateId(
            checkNumberBoolean(res.data?.payment?.update_id!) ? res.data?.payment?.update_id! : null
          );
          integrationPageStore.setChatOpenPayment(true);
        }

        const ordInfo = res.data.payment?.ordInfo?.filter((ord) => ord?.integration?.id === id);

        integrationPageStore.setOrdInfoBlock(ordInfo || null);

        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setError(err?.response?.data?.message);
          setLoading(false);
        }
      });
  };

  const closeSubmitCreate = () => {
    setCreatePayments(false);
    integrationPageStore.setEmptyPayment(false);
  };

  useEffect(() => {
    getApproveThisIntegrations();

    return () => {
      if (integrationPageStore.isEmpyPaymet) {
        integrationPageStore.clearCreateStore(integrationPageStore?.isEmpyPaymet);
      }

      setError(null);
    };
  }, []);

  return (
    <>
      {isLoading && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="h5">Идёт проверка интеграции...</Typography>
          <CircularProgress
            sx={{ maxHeight: 35, minHeight: 35, maxWidth: 35, minWidth: 35, ml: 1 }}
          />
        </Box>
      )}
      {!isLoading && error && (
        <Typography variant="h5" color={"error"} textAlign={"center"}>
          {error}
        </Typography>
      )}
      <Box>
        {!isLoading &&
          error === null &&
          integrationPageStore?.isEmpyPaymet === true &&
          isCreatePayments === false && (
            <CustomButton
              size="small"
              variant="outlined"
              endIcon={<Add fontSize="small" />}
              sx={{ background: "#fff" }}
              onClick={() => setCreatePayments(true)}
              disabled={isReadOnly}>
              Создать оплату
            </CustomButton>
          )}
        {isCreatePayments && (
          <CreateOplata
            readOnly={isReadOnly}
            setPriceValue={setPriceValue}
            update_id={update_id}
            setUpdateId={setUpdateId}
            id={id}
            onChangeCpv={onChangeCpv}
            setCreatePayments={closeSubmitCreate}
          />
        )}
        {!isLoading && error === null && integrationPageStore?.isEmpyPaymet === false && (
          <OplataFullBlock
            readOnly={
              isReadOnly ||
              (integrationPageStore.isOplataBlocked !== null
                ? integrationPageStore.isOplataBlocked
                : false) ||
              integrationPageStore.isClone
            }
            setPriceValue={setPriceValue}
            update_id={update_id}
            setUpdateId={setUpdateId}
            id={id}
            onChangeCpv={onChangeCpv}
          />
        )}
      </Box>
    </>
  );
});
