import { Typography } from "@mui/material";
import { FC } from "react";
import { IBotAudienceField } from "./IBotAudienceField";

export const BotAudienceField: FC<IBotAudienceField> = (props) => {
  const { isBotAudience } = props;
  const renderText = isBotAudience ? "Да" : "Нет";

  return <Typography>{renderText}</Typography>;
};
