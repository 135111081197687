import { makeAutoObservable } from "mobx";
import { IUser } from "../entities/user/models/IUser";

class UserStore {
  user: IUser | null = null;
  currentCompany: string = "";
  isAuth = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(user: IUser) {
    this.user = user;
  }

  setAuth(value: boolean) {
    this.isAuth = value;
  }

  setCurrentCompany(value: string) {
    this.currentCompany = value;
  }

  setFirstName = (value: string) => {
    if (this.user) {
      this.user.firstName = value;
    }
  };

  setLastName = (value: string) => {
    if (this.user) {
      this.user.lastName = value;
    }
  };

  setEmail = (value: string) => {
    if (this.user) {
      this.user.email = value;
    }
  };

  setAvatar = (url: string | null) => {
    if (this.user) {
      this.user.imageUrl = url;
    }
  };

  setUserLogout() {
    this.user = null;
    this.currentCompany = "";
    this.isAuth = false;
  }
}

export default new UserStore();
