import { IChannelTheame } from "../../../../../entities/ChannelTheame/models/IChannelTheame";
import { IChannelUrlFilters } from "../../../../../services/channel/IChannelRequest";
import { IChannelMinMaxResponse } from "../../../../../services/channel/ICnannelResponse";
import { IFromToArray } from "../../../../../services/integration/IntegrationsRequest";
import { IFilterMultiple } from "../../../../Filters/UI/FilterMultiple/IFilterMultiple";
import { IFilterSelectItem } from "../../../../Filters/UI/FilterSelectChip/IFilterSelectChip";

export const getChannelTheameFilters = (channelsTheames: IChannelTheame[]): IFilterSelectItem[] => {
  const theame: IFilterSelectItem[] = channelsTheames.map((theame) => {
    return { name: theame.name, value: theame.code };
  });

  return theame.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
};

export const getAllFilterMultiple = (
  dataMinMax: IChannelMinMaxResponse,
  isLoading: boolean
): IFilterMultiple[] => {
  const allFilters: IFilterMultiple[] = [
    {
      initValue: dataMinMax?.price,
      label: "Цена ₽",
      searchParamName: "price",
      isLoading,
    },
    {
      initValue: dataMinMax?.initialPrice,
      label: "Стоимость ₽",
      searchParamName: "initialPrice",
      isLoading,
    },
    {
      initValue: dataMinMax?.roi,
      label: "ROI %",
      searchParamName: "roi",
      isLoading,
    },
    {
      initValue: dataMinMax?.views,
      label: "Кол-во просмотров",
      searchParamName: "views",
      isLoading,
    },
    {
      initValue: dataMinMax?.audience,
      label: "Кол-во подписчиков",
      searchParamName: "audience",
      isLoading,
    },
    {
      initValue: [0, 100],
      label: "Целевая аудитория %",
      searchParamName: "targetAudience",
      isLoading,
    },
  ];

  return allFilters;
};

export const getFilterChannelQuery = (searchQuery: URLSearchParams): IChannelUrlFilters => {
  const transormValueToArrayFromTo = (
    searchArr: string[],
    isDate?: boolean
  ): IFromToArray<number | string> => {
    const array = !isDate ? searchArr.map((x) => +x) : searchArr;

    const fromToArray: IFromToArray<number | string> = {
      from: array[0],
      to: array[1],
    };
    return fromToArray;
  };

  const option: IChannelUrlFilters | any = {
    id: searchQuery.get("id")
      ? JSON.parse(searchQuery.get("id")!)?.map((x: IFilterSelectItem) => x.value)
      : null,
    foundById: searchQuery.get("foundById")?.split(",") || null,
    releaseById: searchQuery.get("releaseById")?.split(",") || null,
    roi: searchQuery.get("roi")
      ? transormValueToArrayFromTo(searchQuery.get("roi")?.split(",")!)
      : null,
    views: searchQuery.get("views")
      ? transormValueToArrayFromTo(searchQuery.get("views")?.split(",")!)
      : null,
    price: searchQuery.get("price")
      ? transormValueToArrayFromTo(searchQuery.get("price")?.split(",")!)
      : null,
    initialPrice: searchQuery.get("initialPrice")
      ? transormValueToArrayFromTo(searchQuery.get("initialPrice")?.split(",")!)
      : null,
    agency: searchQuery.get("agency")?.split(",") || null,
    "theme.code": searchQuery.get("theme")?.split(",") || null,
    advertiser: searchQuery.get("advertiser")?.split(",") || null,
    searchSystem: searchQuery.get("searchSystem")?.split(",") || null,
    targetAudience: searchQuery.get("targetAudience")
      ? transormValueToArrayFromTo(searchQuery.get("targetAudience")?.split(",")!)
      : null,
    "status.code": searchQuery.get("status")?.split(",") || null,
    "type.code": searchQuery.get("typeCode")?.split(",") || null,
    "size.code": searchQuery.get("size")?.split(",") || null,
    dateFound: searchQuery.get("dateFound")
      ? transormValueToArrayFromTo(searchQuery.get("dateFound")?.split(",")!, true)
      : null,
    dateGiven: searchQuery.get("dateGiven")
      ? transormValueToArrayFromTo(searchQuery.get("dateGiven")?.split(",")!, true)
      : null,
    statisticDate: searchQuery.get("statisticDate")
      ? transormValueToArrayFromTo(searchQuery.get("statisticDate")?.split(",")!, true)
      : null,
    audience: searchQuery.get("audience")
      ? transormValueToArrayFromTo(searchQuery.get("audience")?.split(",")!)
      : null,
    isBanned: searchQuery.get("isBanned") ? searchQuery.get("isBanned") === "true" : null,
    "govVerification.isVerifiedByGov": searchQuery.get("isVerifiedByGov")
      ? searchQuery.get("isVerifiedByGov") === "true"
      : null,
    "govVerification.number": searchQuery.get("isVerifiedByGov") === "notNull" ? "notNull" : null,
    "govVerification.link": searchQuery.get("isVerifiedByGov") === "notNull" ? "null" : null,
    man:
      searchQuery.get("sex") === "man"
        ? { from: 50 }
        : searchQuery.get("sex") === "50%"
        ? 50
        : null,
    woman:
      searchQuery.get("sex") === "woman"
        ? { from: 50 }
        : searchQuery.get("sex") === "50%"
        ? 50
        : null,
    isBotAudience: searchQuery.get("isBotAudience"),
  };

  const filterEmptyFieldOption: any = {};

  for (let key in option) {
    if (option[key] || option[key] === false) {
      filterEmptyFieldOption[key] = option[key];
    }
  }

  return filterEmptyFieldOption;
};
