import { makeAutoObservable } from "mobx";
import { ISettingsSelected } from "../components/IntegrationTable/models/ISettingsSelected";
import { ISettingsSelectedGroup } from "../components/IntegrationTable/models/ISettingsSelectedGroup";
import {
  IIntegrationsGuaranteeResponse,
  IReleaseResponse,
  IUpdatePrice,
} from "../services/integration/IIntegrationsResponse";
import { IReleaseItem } from "../components/ReleaseList/models/IReleaseItem";
import { IGoodItem } from "../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem";
import { IIntegrationCategory } from "../services/layout/layoutResponse";
import { IReferencesTzResponse } from "../services/references/IReferencesResponse";
import { IPostedCommentItem } from "../components/PostedCommentItem/models/IPostedCommentItem";
import { IRenderFilterLists } from "../components/Filters/interfaces/IRenderFilterItem";
import {
  LS_FILTERS_ALL_INTEGRATION,
  LS_FILTERS_INTEGRATION,
} from "../shared/constants/localstorage";

import allFilterListJson from "../shared/mock/filters.mock.json";
import { IUpdateStatusesRequest } from "../services/integration/IntegrationsRequest";
import { IChannelGovVerificationResponse } from "../services/channel/ICnannelResponse";

export interface IDragParams {
  code: string;
  ordering: number;
}

export interface setCheckedParams {
  code: string;
  group: string;
}

class IntegraionTableStore {
  page: number = 1;

  tableItems: any[] = [];

  allFilter: string[] = [];

  isLoading: boolean = true;

  isLoadingSort: boolean = true;

  totalCount: number = 0;

  integrationFilters: IRenderFilterLists[] = localStorage.getItem(LS_FILTERS_INTEGRATION)
    ? JSON.parse(localStorage.getItem(LS_FILTERS_INTEGRATION)!)
    : allFilterListJson.integrationAllFilters;

  integrationAllFilters: IRenderFilterLists[] = localStorage.getItem(LS_FILTERS_ALL_INTEGRATION)
    ? JSON.parse(localStorage.getItem(LS_FILTERS_ALL_INTEGRATION)!)
    : allFilterListJson.integrationAllFilters;

  integrationPresetFilters: IRenderFilterLists[] = [];
  integrationAllPresetFilters: IRenderFilterLists[] = [];

  initSettingsConfirm: ISettingsSelected[] = [];
  initSettingsSelect: ISettingsSelectedGroup[] = [];

  selectLists: ISettingsSelectedGroup[] = [];
  confirmLists: ISettingsSelected[] = [];
  curDragItemParams: IDragParams | null = null;
  isPageNotFound: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIntegrationPresetFilters = (integrationPresetFilters: IRenderFilterLists[]) => {
    this.integrationPresetFilters = integrationPresetFilters;
  };

  setIntegrationPresetAllFilters = (integrationAllPresetFilters: IRenderFilterLists[]) => {
    this.integrationAllPresetFilters = integrationAllPresetFilters;
  };

  setIntegrationFilters = (integrationFilters: IRenderFilterLists[]) => {
    this.integrationFilters = integrationFilters;
  };

  setIntegrationAllFilters = (integrationAllFilters: IRenderFilterLists[]) => {
    this.integrationAllFilters = integrationAllFilters;
  };

  addItem(item: any) {
    this.tableItems = [item, ...this.tableItems];
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setTotalCount(count: number) {
    this.totalCount = count;
  }

  setPage(page: number) {
    this.page = page;
  }

  setAllFilter(array: string[]) {
    this.allFilter = array;
  }

  setIsPageNotFound(value: boolean) {
    this.isPageNotFound = value;
  }

  setTableItems(items: any[]) {
    this.tableItems = items;
  }
  changeUpdateRoiTable = (id: number, value: number, update_id: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          roi: value,
        };
        return newItem;
      }
      return item;
    });
  };

  changeUpdateIdItem = (id: number, update_id: number, type: string) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          status: { code: type },
        };
        return newItem;
      }
      return item;
    });
  };

  changeIntegrationPaymentStatus = (id: number, code: string | null) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          paymentStatus: code ? { code: code } : null,
        };
        return newItem;
      }
      return item;
    });
  };

  changeField = (option: IUpdateStatusesRequest) => {
    const { field, id, new_value, update_id } = option;

    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: new_value,
        };
        return newItem;
      } else return item;
    });
  };

  changePaymentStatusInIntegrationTable = (
    integrationId: number,
    code: string | null,
    update_id: number
  ) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === integrationId) {
        const newItem = {
          ...item,
          paymentStatus: code ? { code: code } : null,
          payment: { ...item.payment, status: code ? { code: code } : null, update_id },
        };
        return newItem;
      }
      return item;
    });
  };

  changePaymentSimpleField = (
    integrationId: number,
    field: string,
    value: any,
    update_id: number
  ) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === integrationId) {
        const newItem = {
          ...item,
          payment: { ...item.payment, [field]: value, update_id },
        };
        return newItem;
      }
      return item;
    });
  };

  changePaymentOrd = (integrationId: number, ordInfo: any[] | null, update_id: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === integrationId) {
        const newItem = {
          ...item,
          payment: { ...item.payment, ordInfo: ordInfo, update_id },
        };
        return newItem;
      }
      return item;
    });
  };

  updateCategory(id: number, update_id: number, newCategory: IIntegrationCategory) {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          category: {
            code: newCategory.code,
            name: newCategory.name,
          },
        };
        return newItem;
      } else {
        return item;
      }
    });
  }

  setInitSettingsSelect(array: ISettingsSelectedGroup[]) {
    this.initSettingsSelect = array;
  }

  setInitSettingsConfirm(array: ISettingsSelected[]) {
    this.initSettingsConfirm = array;
  }

  setSelectedList(array: ISettingsSelectedGroup[]) {
    this.selectLists = array;
  }

  setConfirmList(array: ISettingsSelected[]) {
    this.confirmLists = array;
  }

  addLastComment = (id: number, user: IPostedCommentItem) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        return { ...item, posted_comments: [...item.posted_comments, user] };
      }
      return item;
    });
  };

  onUpdateCommentsArray = (id: number, comments: IPostedCommentItem[]) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        return { ...item, posted_comments: comments };
      }
      return item;
    });
  };

  changePrice(id: number, option: IUpdatePrice) {
    const { cpv, initialPrice, price, update_id } = option;

    const item = this.tableItems.find((x) => x.id === id);
    const index = this.tableItems.findIndex((x) => x.id === id);

    const newItem = {
      ...item,
      initialPrice,
      price,
      cpv,
      update_id,
    };

    this.tableItems[index] = newItem;
  }

  setSelectedChecked(params: setCheckedParams) {
    const { code, group } = params;

    this.selectLists.map((groupArr) =>
      groupArr.group === group
        ? groupArr.fields.map((item) =>
            item.code === code ? (item.isSelected = !item.isSelected) : item
          )
        : groupArr
    );
  }

  addToConfirmList(item: ISettingsSelected) {
    const newArr: any[] = [
      ...this.confirmLists,
      { ...item, ordering: this.confirmLists.length + 1 },
    ];

    this.confirmLists = newArr;
  }

  removeConfirmList(id: string) {
    const newArr: any[] = [
      ...this.confirmLists
        .filter((item) => item.code !== id)
        .map((item, i) => {
          return { ...item, ordering: i + 1 };
        }),
    ];

    this.confirmLists = newArr;
  }

  onConfirmDelete(id: string) {
    this.selectLists.map((groupArr) =>
      groupArr.fields.map((item) =>
        item.code === id ? (item.isSelected = !item.isSelected) : item
      )
    );
  }

  updateLink = (channelName: string, update_id: number, newLink: string) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item?.channel?.name === channelName) {
        const newItem = {
          ...item,
          channel: {
            ...item.channel,
            update_id: update_id,
            link: newLink,
          },
        };
        return newItem;
      } else return item;
    });
  };

  deletePromokod = (integrationId: number, update_id: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === integrationId) {
        return { ...item, update_id, link: null, promokod: null, source: null, shortLink: null };
      }
      return item;
    });
  };

  updateTz = (
    id: number,
    update_id: number,
    newValue: IReferencesTzResponse | null,
    goods: IGoodItem[],
    goodsCategoryCode: string | null,
    goodsCategory: any
  ) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          tz: newValue,
          goods,
          goodsCategoryCode,
          goodsCategory,
        };
        return newItem;
      } else return item;
    });
  };

  updateDate(id: number, update_id: number, newValue: string, field_code: string) {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field_code]: newValue,
        };
        return newItem;
      } else return item;
    });
  }

  updatePaymentsDate(id: number, newValue: string | null, field_code: string) {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          [field_code]: newValue,
        };
        return newItem;
      } else return item;
    });
  }

  changeGurange = (
    id: number,
    guarantee: IIntegrationsGuaranteeResponse | null,
    update_id: number
  ) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          guarantee: guarantee,
          update_id,
        };
        return newItem;
      } else return item;
    });
  };

  addRelease(releaseItem: IReleaseResponse) {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === releaseItem.integrationId) {
        const newItem = {
          ...item,
          releases: [...item.releases, releaseItem.release],
          status: releaseItem.status,
          guarantee: releaseItem.guarantee,
          repeatIntegrationStatus: releaseItem.repeatIntegrationStatus,
          views: releaseItem.views,
        };
        return newItem;
      } else return item;
    });
  }

  editRelease(releaseItem: IReleaseResponse) {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === releaseItem.integrationId) {
        const newItem = {
          ...item,
          releases: item.releases.map((release: IReleaseItem) =>
            release.id === releaseItem.release.id ? releaseItem.release : release
          ),
          update_id: releaseItem.update_id,
          status: releaseItem.status,
          guarantee: releaseItem.guarantee,
          repeatIntegrationStatus: releaseItem.repeatIntegrationStatus,
          views: releaseItem.views,
        };
        return newItem;
      } else return item;
    });
  }

  removeRelease(releaseItem: IReleaseResponse) {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === releaseItem.integrationId) {
        const newItem = {
          ...item,
          releases: item.releases.filter(
            (release: IReleaseItem) => release.id !== releaseItem.release.id
          ),
          update_id: releaseItem.update_id,
          status: releaseItem.status,
          guarantee: releaseItem.guarantee,
          repeatIntegrationStatus: releaseItem.repeatIntegrationStatus,
          views: releaseItem.views,
        };
        return newItem;
      } else return item;
    });
  }

  updateSimpleField = (id: number, update_id: number, field: string, value: any) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: value,
        };
        return newItem;
      }
      return item;
    });
  };

  updateIsVerifiedByGov = (id: number, govVerification: IChannelGovVerificationResponse) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.channel.id === id) {
        const newItem = {
          ...item,
          channel: { ...item.channel, govVerification },
        };
        return newItem;
      }

      return item;
    });
  };

  updateAudience = (id: number, value: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.channel.id === id) {
        return {
          ...item,
          channel: {
            ...item.channel,
            audienceData: { ...item.channel.audienceData, audience: value },
          },
        };
      }
      return item;
    });
  };

  insertGoods(
    id: number,
    goods: IGoodItem[],
    goodsCategoryCode: string | null,
    update_id: number,
    goodsCategory: any
  ) {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          goods,
          goodsCategoryCode,
          update_id,
          goodsCategory,
        };
        return newItem;
      } else return item;
    });
  }

  insertPromokod(option: {
    id: number;
    promokodCode: string;
    dummyArray: string[];
    update_id?: number;
    isActivated: boolean;
    newSource?: string;
  }) {
    const { id, promokodCode, dummyArray, update_id, isActivated, newSource } = option;

    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id: update_id ? update_id : item.update_id,
          promokod: {
            code: promokodCode,
            dummies: dummyArray,
            isActivated,
          },
          source: newSource ? newSource.toLowerCase() : item.source,
        };
        return newItem;
      } else return item;
    });
  }

  updatePaidSum = (id: number, paidSum: number) => {
    this.tableItems = this.tableItems.map((item) => (item.id === id ? { ...item, paidSum } : item));
  };

  updatePromokod(id: number, dummyArray: string[]) {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          promokod: {
            ...item.promokod,
            code: item.promokod.code,
            dummies: dummyArray,
          },
        };
        return newItem;
      } else return item;
    });
  }

  addReleaseByManager = (id: number, field: string, update_id: number, value: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: { id: value },
        };
        return newItem;
      } else return item;
    });
  };

  removeReleaseByManager = (id: number, field: string, update_id: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: null,
        };
        return newItem;
      }
      return item;
    });
  };

  updatePrediction = (option: IUpdateStatusesRequest) => {
    const { field, id, new_value, update_id } = option;
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: new_value,
        };
        return newItem;
      } else return item;
    });
  };

  onDragStart = (params: IDragParams) => {
    this.curDragItemParams = params;
  };

  onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  onDrop = (e: React.DragEvent, params: IDragParams) => {
    e.preventDefault();

    if (
      params.code &&
      params.ordering &&
      this.curDragItemParams?.code &&
      this.curDragItemParams?.ordering &&
      params.code !== this.curDragItemParams.code
    ) {
      const dragItem = { code: this.curDragItemParams.code, ordering: params.ordering };
      const downItem = {
        params,
        code: params.code,
        ordering:
          this.curDragItemParams.ordering < params.ordering
            ? params.ordering - 1
            : params.ordering + 1,
      };

      const afterDragArr: any[] = this.confirmLists.map((item) => {
        if (item.code === dragItem.code) {
          return { ...item, ...dragItem };
        } else if (item.code === downItem.code) {
          return { ...item, ...downItem };
        } else {
          return {
            ...item,
            ordering: item.ordering >= dragItem.ordering ? item.ordering + 1 : item.ordering - 1,
          };
        }
      });

      this.confirmLists = afterDragArr
        .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
        .map((x, i) => {
          return { ...x, ordering: i + 1 };
        });
    }
  };

  sortTableItem(type: string, sortType: "asc" | "desc") {
    this.isLoadingSort = true;

    setTimeout(() => {
      this.tableItems = [...this.tableItems].sort((a, b) => {
        let itemA, itemB;

        if (type === "goodsCategory" || type === "channel") {
          itemA = a[type]?.name ?? "";
          itemB = b[type]?.name ?? "";
        } else if (type === "integration_theme") {
          itemA = a.channel?.theme?.name ?? "";
          itemB = b.channel?.theme?.name ?? "";
        } else if (type === "roi") {
          itemA = a[type] != null ? Number(a[type]) : 0;
          itemB = b[type] != null ? Number(b[type]) : 0;
        } else if (type === "paidAt") {
          itemA = a.payment?.[type] ?? "";
          itemB = b.payment?.[type] ?? "";
        } else if (type === "plannedPaymentDate") {
          itemA = a.payment?.["plannedPaidAt"] ?? "";
          itemB = b.payment?.["plannedPaidAt"] ?? "";
        } else {
          itemA = a[type] ?? "";
          itemB = b[type] ?? "";
        }

        itemA = typeof itemA === "string" ? itemA.toLowerCase() : itemA;
        itemB = typeof itemB === "string" ? itemB.toLowerCase() : itemB;

        if (itemA === itemB) return 0;
        if (sortType === "asc") {
          return itemA < itemB ? -1 : 1;
        } else {
          return itemA > itemB ? -1 : 1;
        }
      });

      setTimeout(() => {
        this.isLoadingSort = false;
      }, 0);
    }, 0);
  }

  delete = (id: number) => {
    this.tableItems = this.tableItems.filter((item) => item.id !== id);
  };
}

export default new IntegraionTableStore();
