import { observer } from "mobx-react-lite";
import { FC } from "react";
import integrationPageStore from "../../../../../../../../store/integrationPage.store";
import { ContractBlock } from "../../../../../../../FinanceCardPage/ui/ContractBlock/ContractBlock";
import { IContractIntegration } from "./IContractIntegration";

export const ContractIntegration: FC<IContractIntegration> = observer((props) => {
  const { readOnly } = props;

  return (
    <ContractBlock
      paymentId={integrationPageStore.oplataId}
      updateContractBlock={integrationPageStore.updateContractBlock}
      setEditAdditionalAgreement={integrationPageStore.setEditAdditionalAgreementField}
      isOriginalsReceived={integrationPageStore.isOriginalsReceived}
      trackNumber={integrationPageStore.trackNumber}
      counteragentTrackNumber={integrationPageStore.counteragentTrackNumber}
      additionalAgreement={integrationPageStore.additionalAgreement}
      setEditContractBlock={integrationPageStore.setEditContractBlock}
      contract={integrationPageStore.contract}
      attachment={integrationPageStore.attachment}
      supplyContract={integrationPageStore.supplyContract}
      counteragentId={integrationPageStore.counteragent?.id!}
      readOnly={readOnly}
      editContractBlock={integrationPageStore.editContractBlock}
      syncContractWithEditContract={integrationPageStore.syncContractWithEditContract}
      closeContarctEdit={integrationPageStore.closeContarctEdit}
      removeAdditionalAgreement={integrationPageStore.removeAdditionalAgreement}
    />
  );
});
