import { FC, memo, useCallback } from "react";
import { TableItem } from "../../../../../../../../components/Table/ui/TableItem";
import { Box } from "@mui/material";
import { ArrowOutward, Autorenew, Check } from "@mui/icons-material";
import { IGoodTableItem } from "./IGoodTableItem";

export const GoodTableItem: FC<IGoodTableItem> = memo((props) => {
  const { tableHead, onNavigate, showCheckboxCell = true, ...restProps } = props;

  const renderIcon = useCallback((hasRefund: boolean, isSampleRefund: boolean): React.ReactNode => {
    if (isSampleRefund) {
      return (
        <Box
          sx={{
            p: 0.5,
            background: "#7ECB41",
            borderRadius: "5px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Check sx={{ color: "#fff" }} />
        </Box>
      );
    } else if (hasRefund) {
      return (
        <Box
          sx={{
            p: 0.5,
            background: "#7876FE",
            borderRadius: "5px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Autorenew sx={{ color: "#fff" }} />
        </Box>
      );
    } else
      return (
        <Box
          sx={{
            p: 0.5,
            background: "#7BB8FF",
            borderRadius: "5px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <ArrowOutward sx={{ color: "#fff" }} />
        </Box>
      );
  }, []);

  return (
    <TableItem
      cells={tableHead}
      {...restProps}
      hover
      showCheckboxCell={showCheckboxCell}
      firstCellIcon={renderIcon(restProps.hasRefund, restProps.isSampleRefund)}
      isShowNavigateIcon
      onNavigate={onNavigate}
      backgroundColor={restProps.isSampleRefund && "#daf9da"}
    />
  );
});
