import { FC } from "react";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { IChannelAudience } from "./IChannelAudience";
import { Box, Stack, Typography } from "@mui/material";
import { getAudiencePercentColor } from "./shared/functions";
import { EditModeButton } from "../../../../components/UI/Buttons/EditModeButton/EditModeButton";
import { ChannelAudienceList } from "./ui/ChannelAudienceList/ChannelAudienceList";
import { getChannelContentOnType } from "./shared/dataList";

export const ChannelAudience: FC<IChannelAudience> = (props) => {
  const {
    data,
    changeField,
    type,
    icons,
    targetAudience,
    submit,
    editData,
    allAge,
    statisticDate,
    isEdit,
    loadingEdit,
    onCloseEdit,
    onOpenEdit,
    disabledEdit,
    isHideEditButton = false,
    listEnableRequired,
    listSubsHeaderRequired,
    disabledInputs,
  } = props;

  return (
    <AsideCardLayout
      headerTitle="Аудитория"
      rightContent={
        !isHideEditButton && (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <Typography>Средняя ЦА:</Typography>
              <Box
                sx={{
                  background: targetAudience ? getAudiencePercentColor(targetAudience) : "inherit",
                  borderRadius: 1,
                  p: `4px 8px`,
                }}>
                <Typography sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                  {targetAudience ? `${targetAudience}%` : "—"}
                </Typography>
              </Box>
            </Stack>
            <EditModeButton
              isEdit={isEdit}
              onClose={onCloseEdit ? onCloseEdit : () => ""}
              onOpenEdit={onOpenEdit ? onOpenEdit : () => ""}
              onSubmit={submit ? submit : () => ""}
              loading={loadingEdit}
              disabledEdit={disabledEdit}
            />
          </Stack>
        )
      }>
      <Box sx={{ pt: "6px" }}>
        <ChannelAudienceList
          list={
            !isEdit
              ? getChannelContentOnType(
                  data,
                  icons,
                  changeField,
                  type,
                  listEnableRequired,
                  listSubsHeaderRequired
                )
              : editData
              ? getChannelContentOnType(
                  editData,
                  icons,
                  changeField,
                  type,
                  listEnableRequired,
                  listSubsHeaderRequired
                )
              : {}
          }
          type={type}
          allAge={allAge}
          statisticDate={statisticDate}
          isEdit={isEdit}
          disabledInputs={disabledInputs}
        />
      </Box>
    </AsideCardLayout>
  );
};
