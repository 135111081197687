import { FC, useCallback, useEffect, useState } from "react";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { TableDefault } from "../../../../components/Table/TableDefault";
import { sentSamplesTableRow } from "./shared/sentSamplesTableRow";
import { GoodTableItem } from "../../../GoodsPage/ui/GoodsTable/ui/GoodsTableListArr/ui/GoodTableItem/GoodTableItem";
import channelCardStore from "../../../../store/channelCard.store";
import { PRODUCT_CARD_OPEN } from "../../../../shared/constants/searchParams";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { LazyPageLoading } from "../../../../components/LazyPageLoading/LazyPageLoading";
import { EmptyText } from "../../../../components/EmptyText/EmptyText";
import { Box, Divider } from "@mui/material";

export const ChannelAsideSentSamples: FC<{ id: number }> = observer(({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const onNavigate = useCallback((id: string | number) => {
    const params = new URLSearchParams(window.location.search);

    if (params.get(PRODUCT_CARD_OPEN)) {
      params.delete(PRODUCT_CARD_OPEN);
      setSearchParams(params);
    }

    params.set(PRODUCT_CARD_OPEN, id?.toString());
    searchParams.set(PRODUCT_CARD_OPEN, id?.toString());
    setTimeout(() => setSearchParams(params), 0);
  }, []);

  const checkSentSamples = channelCardStore.sentSamplesTableItems?.length === 0;

  useEffect(() => {
    setIsLoading(true);
    channelCardStore.getSentSamples(id).finally(() => setIsLoading(false));
  }, []);

  return (
    <AsideCardLayout headerTitle="Отправленные образцы" showDivider={false}>
      {isLoading ? (
        <LazyPageLoading />
      ) : !checkSentSamples ? (
        <TableDefault
          tableRow={sentSamplesTableRow}
          firstCellIcon
          showBurgerCell
          showBoxShadow
          showBorderRadius={false}>
          {channelCardStore.sentSamplesTableItems &&
            channelCardStore.sentSamplesTableItems.length !== 0 &&
            channelCardStore.sentSamplesTableItems.map((item) => (
              <GoodTableItem
                showCheckboxCell={false}
                tableHead={sentSamplesTableRow}
                key={item.integration.id}
                cells={sentSamplesTableRow}
                {...item}
                onNavigate={onNavigate}
              />
            ))}
        </TableDefault>
      ) : (
        <Box>
          <EmptyText mt={2} mb={2}>
            Здесь будет история отправленных обрацов
          </EmptyText>
          <Divider />
        </Box>
      )}
    </AsideCardLayout>
  );
});
