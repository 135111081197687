import { SelectChangeEvent } from "@mui/material";
import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { NotificationAlerts } from "../../../../../../../../components/NotificationAlerts/NotificationAlerts";
import {
  IOrdInfoInsertOrUpdateRequest,
  IOrdInfoRequest,
} from "../../../../../../../../services/payments/IPaymentsRequest";
import PaymentsService from "../../../../../../../../services/payments/payments.service";
import { checkNumberBoolean } from "../../../../../../../../shared/functions/functions";
import integrationPageStore from "../../../../../../../../store/integrationPage.store";
import integrationTableStore from "../../../../../../../../store/integrationTable.store";
import { OrdBlock } from "../../../../../../../FinanceCardPage/ui/OrdBlock/OrdBlock";
import { IOrdIntegrationBlock } from "./IOrdIntegrationBlock";

export const OrdIntegrationBlock: FC<IOrdIntegrationBlock> = observer((props) => {
  const { readOnly, integrationId } = props;

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState<null | string>(null);
  const [isEdit, setEdit] = useState(false);

  const startEdit = () => {
    integrationPageStore.startEditOrdInfoBlock();
    setEdit(true);
  };

  const closeEdit = () => {
    integrationPageStore.closeEditOrdInfoBlock();
    setEdit(false);
  };

  const changeEditCheckbox = (
    id: number,
    field: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (field === "isSelf") {
      const isSelf = event.target.value === "true";
      integrationPageStore.changeEditCheckbox(id, field, isSelf);
      if (!isSelf) {
        integrationPageStore.resetEditOrdInfo();
      }
    } else {
      integrationPageStore.changeEditCheckbox(id, field, event.target.checked);
    }
  };

  const changeSelect = (id: number, event: SelectChangeEvent<string>) => {
    integrationPageStore.setFormatSelect(id, event.target.value);
  };

  const changeSimpleEditInput = (
    value: string | number,
    field: string,
    type?: "contract" | "attachment" | "additionalAgreement",
    id?: number
  ) => {
    if (id) {
      integrationPageStore.changeSimpleEditInput(id, field, value, "");
    }
  };

  const submit = () => {
    if (integrationPageStore.oplataId && checkNumberBoolean(integrationPageStore.paymentUpdateId)) {
      setLoading(true);
      setError(null);

      const option: IOrdInfoInsertOrUpdateRequest = {
        paymentId: integrationPageStore.oplataId,
        update_id: integrationPageStore.paymentUpdateId!,
        info:
          integrationPageStore?.editOrdInfoBlock?.map((item) => {
            const newItem: IOrdInfoRequest = {
              formatCode: item.format?.code || undefined,
              id: item.id || undefined,
              isSelf: Boolean(item.isSelf),
              linkToCreative: item.linkToCreative || "",
              text: `${item.text?.id}` || "",
              eridText: `${item.eridText?.id}` || "",
              token: item.token?.trim() || "",
              hasAct: Boolean(item?.hasAct),
              integration: { id: item?.integration?.id! },
            };
            return newItem;
          }) || [],
      };
      PaymentsService.ordInfoInsertOrUpdate(option)
        .then((res) => {
          const ordInfo = res.data.ordInfo?.filter((item) => item.integration?.id === integrationId)
          integrationPageStore.setOrdInfoBlock(ordInfo || null);
          integrationPageStore.setPaymentUpdateId(res.data?.update_id);
          integrationTableStore.changePaymentOrd(
            integrationId,
            res.data.ordInfo,
            res.data.update_id
          );

          closeEdit();
          setLoading(false);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            setError(err?.response?.data?.message);
            setLoading(false);
          }
        });
    }
  };

  return (
    <>
      <OrdBlock
        isEditMode={isEdit}
        startEdit={startEdit}
        closeEdit={closeEdit}
        readOnly={readOnly}
        submit={submit}
        isLoading={isLoading}
        changeSelect={changeSelect}
        changeEditCheckbox={changeEditCheckbox}
        changeSimpleEditInput={changeSimpleEditInput}
        ordInfo={
          !isEdit
            ? integrationPageStore.ordInfoBlock || []
            : integrationPageStore.editOrdInfoBlock || []
        }
      />
      <NotificationAlerts
        error={{
          text: isError ? isError : "",
          onClose: () => setError(null),
          open: Boolean(isError),
          autoHideDuration: 5000,
        }}
      />
    </>
  );
});
