import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import IntegrationsService from "../../../../../services/integration/integrations.service";
import {
  IInsertDummiesRequest,
  IInsertPromokodRequest,
  IIntegrationPromokodDelete,
} from "../../../../../services/integration/IntegrationsRequest";
import integrationTableStore from "../../../../../store/integrationTable.store";
import { AlertModal } from "../../../../AlertModal/AlertModal";
import { NotificationAlerts } from "../../../../NotificationAlerts/NotificationAlerts";
import { PromokodList } from "../../../../Promokod/PromokodList";
import { IPromokodField } from "./IPromokodField";

export const PromokodField: FC<IPromokodField> = (props) => {
  const {
    dummies,
    promokod,
    id,
    isEditMode,
    readOnly,
    isActivated,
    integrationIsClone,
    repeatIntegrationId,
  } = props;

  const dummyArray = dummies.map((item) => item.code);

  const [isWithSource, setWithSource] = useState(true);
  const [isEdit, setEdit] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [isLoadingDummy, setLoadingDummy] = useState(false);
  const [isShowAddModal, setShowAddModal] = useState(false);

  const addDummysPromokod = (value: string) => {
    setLoadingDummy(true);
    // setError(false)

    const option: IInsertDummiesRequest = {
      mainPromo: promokod,
      dummies: [...dummyArray, value],
    };
    IntegrationsService.insertDummies(option)
      .then((res) => {
        // console.log(res, 'res add dummy prom')

        if (id) {
          integrationTableStore.updatePromokod(+id, res.data.dummies);
        }
        setLoadingDummy(false);
        // setError(false)
        setShowAddModal(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err dumm add')
          // setError(true)
          setLoadingDummy(false);
        }
      });
  };

  const submit = (value: string) => {
    setLoading(true);
    setError(null);

    if (id) {
      const option: IInsertPromokodRequest = {
        id: +id,
        promokod: value.replace(/\s/g, "").toUpperCase(),
        syncWithSource: isWithSource,
      };
      IntegrationsService.insertPromokod(option)
        .then((res) => {
          // console.log(res, 'res prom field')
          if (isWithSource) {
            integrationTableStore.insertPromokod({
              id: +id,
              promokodCode: res.data.promokod?.code,
              dummyArray: res.data.promokod?.dummies,
              update_id: res.data.update_id,
              newSource: res.data.source,
              isActivated: res.data.promokod?.isActivated,
            });
          } else
            integrationTableStore.insertPromokod({
              id: +id,
              promokodCode: res.data.promokod?.code,
              dummyArray: res.data.promokod?.dummies,
              update_id: res.data.update_id,
              isActivated: res.data.promokod?.isActivated,
            });

          setEdit(false);
          setLoading(false);
          setWithSource(true);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, 'err prom field')
            setError(err.response?.data?.message);
            setLoading(false);
          }
        });
    }
  };

  const close = () => {
    setError(null);
    setEdit(false);
    setWithSource(true);
  };

  const removeDummyPromokod = (mainPromo: string, removeValue: string) => {
    const option: IInsertDummiesRequest = {
      mainPromo,
      dummies: dummyArray.filter((item) => item !== removeValue),
    };
    IntegrationsService.insertDummies(option)
      .then((res) => {
        // console.log(res, 'res dum delete')
        integrationTableStore.updatePromokod(+id!, res.data.dummies);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err dummy remove')
        }
      });
  };

  const onDeletePromokod = (id: number) => {
    const option: IIntegrationPromokodDelete = {
      id: id,
      promokod,
      isClone: integrationIsClone,
      repeatIntegrationId: repeatIntegrationId,
    };

    IntegrationsService.deletePromokod(option)
      .then((res) => {
        integrationTableStore.deletePromokod(id, res.data.update_id);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
        }
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithSource(event.target.checked);
  };

  return (
    <>
      <PromokodList
        dummies={dummyArray}
        promokod={promokod}
        id={id}
        isEditMode={isEditMode}
        openEdit={() => setEdit(true)}
        readOnly={readOnly}
        isAddDummy={isShowAddModal}
        setAddDummy={() => setShowAddModal(true)}
        removeDummyPromokod={removeDummyPromokod}
        isActivated={isActivated}
        onDeletePromokod={id ? () => onDeletePromokod(+id) : () => ""}
        addDummyComponent={
          <AlertModal
            buttonCancelName={"Закрыть"}
            buttonSubmitName={"Сохранить"}
            showTextField
            title="Форма добавления промокода"
            open={isShowAddModal}
            onClose={() => setShowAddModal(false)}
            placeholder="Введите промокод"
            onSubmit={addDummysPromokod}
            loading={isLoadingDummy}
            error={error ? true : false}
            promokodFlag={true}
          />
        }
      />
      {isEdit && (
        <AlertModal
          buttonCancelName={"Закрыть"}
          buttonSubmitName={"Сохранить"}
          showTextField
          loading={loading}
          title="Форма редактирования промокода"
          open={isEdit}
          onClose={close}
          onSubmit={submit}
          placeholder="Введите промокод"
          initValue={promokod}
          textCase="uppercase"
          error={error ? true : false}
          subContent={
            <Box>
              <FormControlLabel
                control={
                  <Checkbox disabled={loading} checked={isWithSource} onChange={handleChange} />
                }
                label="Синхронизировать с источником"
              />
            </Box>
          }
          promokodFlag={true}
        />
      )}
      <NotificationAlerts
        error={{
          onClose: () => setError(null),
          open: error ? true : false,
          text: error ? error : "",
        }}
      />
    </>
  );
};
